export default () => ([
  {
    path: '',
    redirect: 'profile',
  },
  {
    path: 'profile',
    name: 'account-settings-profile',
    component: () => import('@/views/account-2023/settings/Profile.vue'),
  },
  {
    path: 'billing',
    name: 'account-settings-billing',
    component: () => import('@/views/account-2023/settings/Billing.vue'),
  },
  {
    path: 'artist',
    name: 'account-settings-artist',
    component: () => import('@/views/account/settings/Artist.vue'),
  },
  {
    path: 'payout',
    name: 'account-settings-payout',
    component: () => import('@/views/account-2023/settings/Payout.vue'),
  },
]);
