// import { listPayoutMethods, createRegistrationLink, deletePayoutMethod } from '@motionelements/core/src/api/payout-methods.js';
import loadingMutation from '@motionelements/core/src/store/mutations-mixins/loadingMutation.js';
import * as payoutMethodsAPI from '@motionelements/core/src/api/payout-methods.js';

const state = {
  methods: [],
  payoneerRegistrationLink: null,
  //
  loading: false,
};

const getters = {
  getPayoneerMethods(state) {
    return state.methods ? state.methods.filter(method => method.type === 'payoneer') : null;
  },
  getPaypalMethods(state) {
    return state.methods ? state.methods.filter(method => method.type === 'paypal') : null;
  },
  getWiseMethods(state) {
    return state.methods ? state.methods.filter(method => method.type === 'wise') : null;
  },
  //
  defaultPayoutMethod(state) {
    // return state.methods.find(({ default: isDefault }) => isDefault);
    return state.methods[0];
  },
};

const actions = {
  LIST_PAYOUT_METHODS({ commit }, type) {
    commit('setLoading', true);
    return payoutMethodsAPI.listPayoutMethods({ type })
      .then((response) => {
        // response.data.data[0].status = 'declined';
        commit('setPayoutMethods', response.data.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  CREATE_PAYONEER_REGISTRATION_LINK({ commit }, redirectUrl) {
    return payoutMethodsAPI.createRegistrationLink({
      type: 'payoneer',
      redirect_url: redirectUrl,
    })
      .then((response) => {
        const url = _.get(response.data.data, 'registration.url');
        if (url) commit('setPayoneerRegistrationLink', url);
      })
      .catch(err => {
        console.dir(err);
      });
  },
  // eslint-disable-next-line
  DELETE_PAYOUT_METHOD({ commit, dispatch }, payoutMethodId) {
    return payoutMethodsAPI.deletePayoutMethod(payoutMethodId)
      .then((response) => {
        commit('removeMethod', payoutMethodId);
        return response;
      });
  },
  // eslint-disable-next-line
  updatePayoutMethod({ state, commit }, { id, data }) {
    // commit('setLoading', true);
    return payoutMethodsAPI.updatePayoutMethod(id, data)
      .then(({ data: { data: payoutMethod } }) => {
        console.log(payoutMethod);
        commit('updatePayoutMethod', payoutMethod);
        return payoutMethod;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        // commit('setLoading', false);
      });
  },
};

const mutations = {
  setPayoutMethods(state, methods) {
    state.methods = methods;
  },
  setPayoneerRegistrationLink(state, link) {
    state.payoneerRegistrationLink = link;
  },
  removeMethod(state, id) {
    const index = state.methods.findIndex(method => method.id === id);
    console.log(index);
    if (index > -1) state.methods.splice(index, 1);
  },
  updatePayoutMethod(state, payoutMethod) {
    state.methods.forEach(method => {
      method.default = false;
    });
    const index = state.methods.findIndex(({ id }) => id === payoutMethod.id);
    state.methods.splice(index, 1, payoutMethod);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, ...loadingMutation },
};
