import Vue from 'vue';
import Router from 'vue-router';

import { commonLanguageLogic, updateLanguageMessages } from '@motionelements/core/src/services/language.service.js';
import i18n from '@/lang/i18n.js';
import { preLoadedLanguages } from '@/lang';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return false;
    }

    const position = { x: 0, y: 0 };
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 0);
    });
  },
});

router.beforeEach((to, from, next) => {
  commonLanguageLogic({ to, from }, preLoadedLanguages, i18n)
    .then(languageCode => {
      if (languageCode) { // lazy load language
        import(/* webpackChunkName: "i18n-[request]" */ `@/lang/json/${languageCode}.json`)
          .then(messages => {
            updateLanguageMessages({ to, from, next }, languageCode, messages, preLoadedLanguages, i18n);
            next();
          });
      } else {
        next();
      }
    });
});

export default router;
