import VuexPersistence from 'vuex-persist';
import {
  STORAGE_VUEX_ACCOUNT_KEY,
  STORAGE_VUEX_ACCOUNT_VERSION,
} from '@motionelements/core/src/app.constants.js';

export default new VuexPersistence({
  key: STORAGE_VUEX_ACCOUNT_KEY,
  storage: window.sessionStorage,
  reducer: ({ favorites, user }) => ({
    hash: `${user.jwt.id}|${user.language}|${user.currencyCode}|${STORAGE_VUEX_ACCOUNT_VERSION}`,
    user: user,
    favorites: {
      defaultFolder: {
        totalCount: _.get(favorites, 'defaultFolder.totalCount'),
      },
    },
    // cart: cart,
  }),
  filter: (mutation) => ([
    'user/setJwtId',
    'user/setMember',
    'user/setLoading',
    'user/setLanguage',
    'favorites/setDefaultFolderTotalCount',
    // 'cart/setCartItems',
    // 'cart/clearCart',
  ].includes(mutation.type)),
});
