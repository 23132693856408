import catAndDlp from './cat_dlp.js';
// import artist from './artist.js';
import account from './account.js';
import signup from './signup.js';
// import legal from './legal.js';
import order from './order.js';
import landingPages from './landing-pages.js';
import corporate from './corporate.js';
import categories from './categories.js';
import verify from './verify.js';
import affiliate from './affiliate.js';
import developerConsole from './developer.js';
import forms from './forms.js';
import coupon from './coupon.js';

/**
 * generate langPrefix to all route
 * all route name must be unique
 *
 * @param {Array} routes{path, name, components}
 * @returns {Array}
 */

const languageRegex = process.env.VUE_APP_LANGUAGE_CODES.split(',').join('|');

// export default routes;
export default [
  ...account,
  // ...artist,
  ...catAndDlp,
  // ...legal,
  ...signup,
  ...order,
  ...landingPages,
  ...corporate,
  ...categories,
  ...verify,
  ...affiliate,
  ...developerConsole,
  ...forms,
  ...coupon,
  {
    path: '/:lang?/service-unavailable',
    name: 'service-unavailable',
    component: () => import(/* webpackChunkName: "login" */ '@/views/ServiceUnavailable.vue'),
    meta: { layout: 'minimal' },
  },
  //
  {
    path: `/:lang(${languageRegex})?/account/payout-methods/verify-paypal`,
    name: 'verify-paypal',
    component: () => import('@/views/account-2023/payout-methods/PayPalVerify.vue'),
  },
  {
    path: `/:lang(${languageRegex})?/*`,
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
  },
];
