import en from './json/en.json';
import ja from './json/ja.json';
import ko from './json/ko.json';
import zhHant from './json/zh-hant.json';

const languages = {
  en,
  ja,
  ko,
  'zh-hant': zhHant,
};

const preLoadedLanguages = Object.keys(languages);

export {
  languages,
  preLoadedLanguages,
};
