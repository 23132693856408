<template>
  <NavLink :to="intendedUrl || to" :repo="repo" class="navbar-brand ml-n3">
    <!-- black logo for light mode-->
    <img
      v-show="!showWhiteLogo"
      class="logo-light img-light-mode"
      :src="logoUiLight"
      alt="MotionElements"
      @contextmenu="handler"
    />
    <!-- white logo for dark mode-->
    <img
      class="logo-dark"
      :class="!showWhiteLogo && 'img-dark-mode'"
      :src="logoUiDark"
      alt="MotionElements"
      @contextmenu="handler"
    />
  </NavLink>
</template>

<script>
import { mapState } from 'vuex';
import NavLink from './NavLink.vue';

export default {
  name: 'nav-logo',
  components: {
    NavLink,
  },
  props: {
    showWhiteLogo: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '/',
    },
    repo: {
      type: String,
      default: 'frontend-channels2022',
    },
  },
  computed: {
    ...mapState({
      uiMode: state => state.user.preferences.ui.mode,
    }),
    logoUiLight() {
      return 'https://dg0np5s1f9lxp.cloudfront.net/img/logo/MotionElements-logo-masthead-220503.svg';
    },
    logoUiDark() {
      return 'https://dg0np5s1f9lxp.cloudfront.net/img/logo/MotionElements-logo-masthead-220503-inverse.svg';
    },
    intendedUrl() {
      const intendedUrl = this.$route.query.intended_url;
      if (intendedUrl && intendedUrl.includes('motionelements.com')) {
        return intendedUrl;
      }
      return false;
    },
  },
  methods: {
    handler: function (e) {
      e.preventDefault();
      const resolved = this.$router.resolve(this.$link('/brand').fullPath()).resolved;
      if (resolved.name && resolved.name !== 'not-found' && resolved.matched.length > 0) {
        this.$router.push(this.$link('/brand').fullPath());
      } else {
        window.location.href = this.$link('/brand').url();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-brand {
  flex: 0 0 auto;
  img {
    height: 80px;
    @include media-breakpoint-down(md) {
      height: 64px;
    }
  }
}
</style>
