import { listAllCards } from '@motionelements/core/src/api/payment-methods.js';
import { differenceInMonths } from '@motionelements/core/src/helpers/date.js';

function isExpiring(card) {
  const months = 5;
  const cardDate = new Date(card.expYear, card.expMonth - 1);
  return differenceInMonths(cardDate, new Date()) < months && differenceInMonths(cardDate, new Date()) > -1;
}

function isExpired(card) {
  const cardDate = new Date(card.expYear, card.expMonth - 1);
  return differenceInMonths(cardDate, new Date()) < 0;
}

const state = {
  list: [],
  loading: false,
  canceledToUpdateSubscriptionCard: false,
};

const getters = {
  expiringCards(state) {
    return state.list.filter(pm => isExpiring(pm.card));
  },
  expiredCards(state) {
    return state.list.filter(pm => isExpired(pm.card));
  },
  expiringOnDemandCards(state) {
    return getters.expiringCards(state).filter(pm => pm.orderType === 'on_demand');
  },
  expiredOnDemandCards(state) {
    return getters.expiredCards(state).filter(pm => pm.orderType === 'on_demand');
  },
  expiringSubscriptionCards(state) {
    return getters.expiringCards(state).filter(pm => pm.orderType === 'subscription');
  },
  expiredSubscriptionCards(state) {
    return getters.expiredCards(state).filter(pm => pm.orderType === 'subscription');
  },

  subscriptionPaymentMethods(state) {
    return state.list.filter(pm => pm.orderType === 'subscription');
  },
  subscriptionActivePaymentMethods(state) {
    return state.list.filter(pm => pm.orderType === 'subscription' && !isExpired(pm.card));
  },
  onDemandPaymentMethods(state) {
    return state.list.filter(pm => pm.orderType === 'on_demand');
  },
};

const actions = {
  GET_PAYMENT_METHODS({ commit }) {
    commit('setLoading', true);
    return listAllCards()
      .then(({ data: { data } }) => {
        commit('setPaymentMethods', data);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPaymentMethods(state, methods) {
    state.list = methods;
  },
  addPaymentMethod(state, pm) {
    state.list.unshift(pm);
  },
  deletePaymentMethod(state, pmId) {
    const index = state.list.findIndex(pm => pm.id === pmId);
    state.list.splice(index, 1);
  },
  updatePaymentMethod(state, { pmIdToDelete, paymentMethod }) {
    this.commit('paymentMethods/deletePaymentMethod', pmIdToDelete);
    this.commit('paymentMethods/addPaymentMethod', paymentMethod);
  },
  setCancelToUpdateSubscriptionCard(state, status) {
    state.canceledToUpdateSubscriptionCard = status;
  },
};

export { isExpiring, isExpired };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
