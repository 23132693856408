<template>
  <b-dropdown
    :variant="`${variant} position-relative`"
    right
    no-caret
    menu-class="nav-dropdown"
    @show="emitOnShow"
  >
    <template #button-content>
      <font-awesome-icon :icon="faShoppingCart" />
      <span class="dropdown-toggle-tooltip" v-tooltip="$t('header.cart')"></span>
      <span v-if="getTotalItemsCount" class="badge badge-primary p-0 rounded-circle font-weight-normal">
        {{ getTotalItemsCount }}
      </span>
    </template>
    <CartNavList />
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { faShoppingCart } from '@motionelements/assets/src/js/me-font/icons-fa6';

export default {
  name: 'CartNavDropdown',
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  components: {
    CartNavList: () => import('./CartNavList.vue'),
  },
  data() {
    return {
      faShoppingCart,
    };
  },
  computed: {
    ...mapGetters({
      getTotalItemsCount: 'cart/getTotalItemsCount',
    }),
  },
  methods: {
    emitOnShow() {
      this.$emit('onShow');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-toggle-tooltip {
  position: absolute;
  inset: 0;
  border-radius: 50%;
}
::v-deep {
  .nav-dropdown {
    width: 368px;
    max-width: 100vw;
  }
}
</style>
