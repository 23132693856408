import api from '@motionelements/core/src/api/base-api-v2.js';

import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';


/*
export const searchElements = async (mediaType, params) => {
  params = params || {};
  const request = await api.get(`/v2/search/${mediaType}`, {
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  })
    .then(res => res.data)
    .catch((err) => {
      console.error(err);
    });

  return request;
};
 */

// eslint-disable-next-line import/prefer-default-export
export const searchElements = async (mediaType, params, axiosConfig) => {
  params = params || {};
  mediaType = mediaType.replace('_', '-');
  return api.get(`/v2/search/${mediaType}`, {
    ...(axiosConfig || {}),
    params: {
      ...api.getLocaleParams(),
      ...snakeCaseKeys(params),
      type: 'element',
    },
  });
};

export const getSearchTypeaheadResult = async (info) => {
  const request = await api.get('/v2/suggest', {
    withCredentials: false,
    params: info,
  });
  return request;
};

export const getUploadSetting = async () => {
  const request = await api.get('/v2/upload/url');
  return request;
};

// export const doUploadMediaToS3 = async (payload) => {
//   const request = await axios.create({
//     method: 'post',
//     baseURL: process.env.VUE_APP_AWS_S3_URL,
//     data: payload,
//     onUploadProgress(progressEvent) {
//       // trigger upload progress block when doing upload
//       const info = {
//         status: true,
//         progress: Math.round((progressEvent.loaded / progressEvent.total) * 100),
//       };
//       // console.log(info)
//       store.dispatch('site/setLoadingProgress', info);
//     },
//   });
//
//   return request;
// };

export const getUploadAnalyze = async (info) => {
  const searchMode = info.mediaType;

  const request = await api.post(`/v2/upload/analyze-${searchMode}`, info);
  return request;
};

