import api from '@motionelements/core/src/api/base-api-v2';

const baseUrl = '/v2/payout-methods';

// eslint-disable-next-line
export const listPayoutMethods = (params) => { // params: { type: 'payoneer' }
  return api.get(baseUrl, {
    params: {
      ...(params || {}),
      ...api.getLocaleParams(),
    },
  });
};

// eslint-disable-next-line
export const createRegistrationLink = (data) => { // data: { type: 'payoneer' }
  return api.request('post', `${baseUrl}`, {
    data,
    params: {
      ...api.getLocaleParams(),
    },
  });
};

// eslint-disable-next-line
export const deletePayoutMethod = (payoutMethodId) => {
  return api.request('delete', `${baseUrl}/${payoutMethodId}`);
};

// eslint-disable-next-line
export const createPayoutMethod = (params) => {
  return api.request('post', baseUrl, {
    params,
  });
};

// eslint-disable-next-line
export const verifyPayoutMethod = ({ type, code }) => {
  return api.request('post', `${baseUrl}/verify`, {
    params: {
      type,
      code,
    },
  });
};
// eslint-disable-next-line
export const payoutMethodResendCode = (type) => {
  return api.request('post', `${baseUrl}/verify/resend`, {
    params: {
      type,
    },
  });
};

// eslint-disable-next-line
export const updatePayoutMethod = (id, data) => {
  return api.request('patch', `${baseUrl}/${id}`, {
    data,
  });
};
