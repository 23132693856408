<template>
  <div>
    <div class="container pl-0">
      <nav class="navbar py-0 px-0">
        <div class="d-flex align-items-center">
          <button
            v-b-toggle.menu-mobile
            data-qa="home-page-nav-explore-btn"
            class="btn btn-menu p-2 rounded-0 mr-3"
            :class="isTransparentBg ? 'btn-translucent-dark no-invert' : 'btn-light'"
          >
            <font-awesome-icon :icon="icons.faBars" class="text-2xl align-top" />
          </button>

          <NavLogo class="mr-3 mr-lg-4 px-0" :showWhiteLogo="isTransparentBg" />
        </div>

        <NuxtClientOnly>
          <div class="d-flex align-items-center">
            <button
              v-if="isLoggedIn"
              v-b-toggle.menu-cart-nav-mobile
              class="btn btn-custom-link px-2 position-relative"
              :class="{ 'text-white no-invert': isTransparentBg }"
              data-qa="home-page-nav-cart-btn-mobile"
            >
              <font-awesome-icon :icon="icons.faShoppingCart" class="text-2xl align-top" />
              <span v-if="getTotalItemsCount" class="badge badge-primary p-0 rounded-circle font-weight-normal">
                {{ getTotalItemsCount }}
              </span>
            </button>

            <button
              v-if="!isLoggedIn"
              v-b-toggle.menu-lang-nav-mobile
              class="btn btn-custom-link px-2"
              :class="{ 'text-white no-invert': isTransparentBg }"
              data-qa="home-page-nav-lang-btn-mobile"
            >
              <font-awesome-icon :icon="icons.faMeEarth" class="text-2xl align-top" />
            </button>

            <button
              v-if="isLoggedIn"
              v-b-toggle.user-nav-mobile
              class="btn btn-custom-link px-2"
              :class="{ 'text-white no-invert': isTransparentBg }"
              data-qa="home-page-nav-user-btn-mobile"
            >
              <font-awesome-icon :icon="icons.faUser" class="text-2xl align-top" />
            </button>
            <button
              v-else
              class="btn btn-custom-link px-2"
              :class="{ 'text-white no-invert': isTransparentBg }"
              @click="goToLogin()"
            >
              <font-awesome-icon :icon="icons.faSignIn" class="text-2xl align-top" />
            </button>
          </div>
        </NuxtClientOnly>
      </nav>
    </div>

    <NuxtClientOnly>
      <div>
        <CustomSidebar id="menu-mobile" :title="$t('header.menu')">
          <ExploreMenu />
        </CustomSidebar>
        <CustomSidebar
            v-if="!isLoggedIn"
            id="menu-lang-nav-mobile"
            :title="$t('header.select_language')"
        >
          <LanguagesList />
        </CustomSidebar>
        <CustomSidebar v-if="isLoggedIn" id="menu-cart-nav-mobile" :title="$t('header.cart')">
          <CartNav />
        </CustomSidebar>
        <CustomSidebar v-if="isLoggedIn" id="user-nav-mobile" :title="$t('header.profile')">
          <UserNavMobile data-qa="home-page-nav-user-mobile" />
        </CustomSidebar>
      </div>
    </NuxtClientOnly>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapState, mapGetters } from 'vuex';

import {
  faSignIn,
  faBars,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faUser,
  faShoppingCart,
} from '@motionelements/assets/src/js/me-font/icons-fa6';

import { faMeEarth } from '@motionelements/assets/src/js/me-font/icons-channels2022';

import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';

import NavLogo from './NavLogo.vue';

export default {
  name: 'TheNavBarMobile',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authMixin],
  directives: {
    ClickOutside,
  },
  components: {
    NuxtClientOnly: () => import('./NuxtClientOnly.vue'),
    LanguagesList: () => import('@motionelements/core/src/components/modules/common/MenuLanguagesList.vue'),
    NavLogo,
    ExploreMenu: () => import('./ExploreMenu.vue'),
    UserNavMobile: () => import('./UserNavMobile.vue'),
    CustomSidebar: () => import('./CustomSidebar.vue'),
    CartNav: () => import('./CartNavList.vue'),
  },
  data() {
    return {
      icons: {
        faBars,
        faSignIn,
        faMeEarth,
        faShoppingCart,
        faUser,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    ...mapGetters({
      getTotalItemsCount: 'cart/getTotalItemsCount',
    }),
    isTransparentBg() {
      return this.isTransparent && !this.isExploreMenuVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-menu {
  height: 64px;
  width: 64px;
}
</style>
