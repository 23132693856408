import * as subscriptionApi from '@motionelements/core/src/api/subscription.js';
import paginationMutations from '@motionelements/core/src/store/mutations-mixins/paginationMutations.js';
import { differenceInMonths } from '../../helpers/date';

const state = {
  subscriptionsList: [],
  pagination: {
    page: 1,
    perPage: 10,
    totalCount: 0,
  },
  subscriptionsListLoaded: false,
  //
  isLoadingSubscriptions: false,
  liveSubscriptionsList: [], // ['active', 'past_due', 'unpaid']
  //
  defaultPaymentMethodSubscriptions: [],
  defaultPaymentMethodLoading: false,
};

const getters = {
  overdueSubscriptions: state => {
    const subscriptions = state.liveSubscriptionsList.length > 0 ? state.liveSubscriptionsList : state.subscriptionsList;
    return subscriptions.filter(subscription => ['past_due', 'unpaid'].includes(subscription.status));
  },
  isSubscriptionRenewalSoon: state => {
    const activeSubscriptions = state.liveSubscriptionsList.filter(subscription => subscription.status === 'active');
    if (activeSubscriptions.length) {
      const now = new Date();
      return activeSubscriptions.findIndex(x => differenceInMonths(new Date(x.currentPeriodEnd * 1000), now) <= 1) !== -1;
    }

    return false;
  },
  // eslint-disable-next-line
  hasUncanceledSubscription: state => {
    return Array.isArray(state.liveSubscriptionsList)
      && !!state.liveSubscriptionsList
        .find(({ status, canceledAt }) => status === 'active' && !canceledAt);
  },
  // eslint-disable-next-line
  defaultPaymentMethods: state => {
    return state.defaultPaymentMethodSubscriptions.map(({ defaultPaymentMethod, id: subId }) => ({ pm: _.get(defaultPaymentMethod, 'id', null), subId }));
  },
};

const mutations = {
  setSubscriptions(state, data) {
    state.subscriptionsList = data;
  },
  setSubscriptionsListLoaded(state, boolean) {
    state.subscriptionsListLoaded = boolean;
  },
  setSubscriptionsLoading(state, data) {
    state.isLoadingSubscriptions = data;
  },
  setLiveSubscriptions(state, data) {
    state.liveSubscriptionsList = data;
  },
  //
  setDefaultPaymentMethodSubscriptions(state, data) {
    state.defaultPaymentMethodSubscriptions = data;
  },
  setDefaultPaymentMethodLoading(state, boolean) {
    state.defaultPaymentMethodLoading = boolean;
  },
  //
  updateDefaultPaymentMethod(state, { pmId, subId, defaultPaymentMethod }) {
    let targetSub;
    if (pmId) {
      targetSub = state.defaultPaymentMethodSubscriptions.find(({ defaultPaymentMethod: { id } }) => id === pmId);
    } else if (subId) {
      targetSub = state.defaultPaymentMethodSubscriptions.find(({ id }) => id === subId);
    }
    if (targetSub) targetSub.defaultPaymentMethod = defaultPaymentMethod;
  },
  //
  updateLiveSubscription(state, subscription) {
    const index = state.liveSubscriptionsList.findIndex(({ id }) => id === subscription.id);
    if (index > -1) state.liveSubscriptionsList.splice(index, 1, subscription);
  },
};

const actions = {
  listSubscriptions({ commit, state }) {
    commit('setSubscriptionsLoading', true);
    subscriptionApi.listSubscriptions({
      page: state.pagination.page,
      per_page: state.pagination.perPage,
    })
      .then((response) => {
        commit('setSubscriptionsListLoaded', true);
        commit('setSubscriptions', response.data.data);
        commit('setTotalCount', response.data.totalCount);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit('setSubscriptionsLoading', false);
      });
  },
  listLiveSubscriptions({ commit }) {
    commit('setSubscriptionsLoading', true);
    return subscriptionApi.listSubscriptions({
      status: 'live',
    })
      .then((response) => {
        // response.data.data[0].status = 'past_due';
        // response.data.data[0].cancelAtPeriodEnd = true;
        // response.data.data[0].canceledAt = new Date('2024-03-21') / 1000;
        commit('setLiveSubscriptions', response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit('setSubscriptionsLoading', false);
      });
  },
  listDefaultPaymentMethodSubscriptions({ commit }) {
    commit('setDefaultPaymentMethodLoading', true);
    return subscriptionApi.listSubscriptions({
      status: 'live',
      'fields[subscription]': 'default_payment_method',
    })
      .then((response) => {
        // response.data.data[0].defaultPaymentMethod = null;
        commit('setDefaultPaymentMethodSubscriptions', response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit('setDefaultPaymentMethodLoading', false);
      });
  },
  //
  // eslint-disable-next-line
  updateSubscription({ commit }, { id, payload }) {
    return subscriptionApi.updateSubscription(id, payload)
      .then(({ data: { data: subscription } }) => {
        console.log(subscription);
        commit('updateLiveSubscription', subscription);
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations: { ...mutations, ...paginationMutations },
  actions,
};
