import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import accountSettings from './account-settings.js';
/**
 * CHANNEL PAGE ROUTES
 * * ROUTE FILE NAME NEED SAME WITH REAL PATH! EXCEPT HOME
 * NORMAL CHANNEL PAGE: home, video, animation ...
 * FREE PAGE: free/stock-footage, free/gifs, free/premiere-pro-templates
 */

import routeConst from './const.js';

export default [
  {
    path: `/:lang?/${routeConst.ACCOUNT}/${routeConst.LOGIN}`,
    alias: '/:lang?/login',
    name: routeConst.LOGIN,
    component: () => {
      if (featureFlagMixin.methods.isFeatureEnabled('login_signup_redesign_2023')) {
        return import(/* webpackChunkName: "login" */ '@/views/redesign-2023/login/Login.vue');
      }
      return import(/* webpackChunkName: "login" */ '@/views/login/Login.vue');
    },
    meta: { layout: 'minimal' },
  },
  {
    path: '/:lang?/password/reset',
    alias: '/:lang?/account/forgot-password', // @deprecated
    name: routeConst.LOST_PASSWORD,
    component: () => import(/* webpackChunkName: "lost-password" */ '@/views/account/password/LostPassword.vue'),
    meta: { layout: 'minimal' },
  },
  {
    // path: `/:lang?/${routeConst.ACCOUNT}/${routeConst.RESET_PASSWORD}`,
    path: '/:lang?/password/reset/:code',
    name: routeConst.RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/account/password/ResetPassword.vue'),
    meta: { layout: 'minimal' },
  },
  {
    path: `/:lang?/${routeConst.ACCOUNT}/${routeConst.PHOTO_ID}`,
    name: `${routeConst.ACCOUNT}-${routeConst.PHOTO_ID}`,
    component: () => import(/* webpackChunkName: "photo-id" */ '@/views/account/PhotoIdUpload.vue'),
  },
  {
    path: `/:lang?/${routeConst.ACCOUNT}/closed`,
    name: `${routeConst.ACCOUNT}-closed`,
    component: () => import(/* webpackChunkName: "account-closed" */ '@/views/account-2023/AccountClosed.vue'),
  },
  {
    path: `/:lang?/${routeConst.ACCOUNT}/delete`,
    name: `${routeConst.ACCOUNT}-delete`,
    component: () => import('@/views/account-2023/DeleteAccount.vue'),
  },
  {
    path: `/:lang?/${routeConst.ACCOUNT}`,
    component: () => import(('@/views/account-2023/Account.vue')),
    children: [
      {
        path: '',
        redirect: routeConst.DASHBOARD,
      },
      {
        path: `/:lang?/${routeConst.ACCOUNT}/close`,
        name: `${routeConst.ACCOUNT}-close`,
        component: () => import('@/views/account/AccountClose.vue'),
      },
      {
        path: `/:lang?/${routeConst.ACCOUNT}/email`,
        name: `${routeConst.ACCOUNT}-email`,
        component: () => import('@/views/account/AccountChangeEmail.vue'),
      },
      {
        path: routeConst.DASHBOARD,
        name: `${routeConst.ACCOUNT}-${routeConst.DASHBOARD}`,
        component: () => import('@/views/account-2023/dashboard/Dashboard.vue'),
      },
      {
        path: routeConst.SETTINGS,
        // name: `${routeConst.ACCOUNT}-${routeConst.SETTINGS}`,
        component: () => import('@/views/account-2023/settings/Settings.vue'),
        children: accountSettings(),
      },
      {
        path: routeConst.CREDITS,
        name: `${routeConst.ACCOUNT}-${routeConst.CREDITS}`,
        component: () => import('@/views/account/credits/Credits.vue'),
      },
      {
        path: routeConst.SUBSCRIPTION,
        // name: `${routeConst.ACCOUNT}-${routeConst.SUBSCRIPTION}`,
        component: () => import('@/views/account-2023/subscription/Subscription.vue'),
        children: [
          {
            path: '',
            redirect: 'overview',
          },
          {
            path: 'cancel',
            name: 'account-cancel-subscription-proceed',
            component: () => import('@/views/account-2023/subscription/cancellation/CancelSubscriptionProceed.vue'),
            meta: { hideSidebar: true },
          },
          // {
          //   path: 'feedback',
          //   name: 'account-cancel-subscription-feedback',
          //   component: () => import('@/views/account-2023/subscription/cancellation/CancelSubscriptionFeedback.vue'),
          //   meta: { hideSidebar: true },
          // },
          {
            path: 'feedback-submitted',
            name: 'account-subscription-feedback-submitted',
            component: () => import('@/views/account-2023/subscription/cancellation/CancelSubscriptionSubmitted.vue'),
            meta: { hideSidebar: true },
          },
          {
            path: 'overview',
            name: 'account-subscription-overview',
            component: () => import('@/views/account-2023/subscription/Overview.vue'),
          },
          {
            path: 'transactions',
            name: 'account-subscription-transactions',
            component: () => import('@/views/account-2023/subscription/Transactions.vue'),
          },
          {
            path: ':id/payment-methods',
            name: 'account-subscription-edit-payment-methods',
            component: () => import('@/views/account-2023/subscription/sections/EditSubscriptionPaymentMethods.vue'),
          },
        ],
      },
      {
        path: routeConst.PAYMENT_METHODS,
        name: `${routeConst.ACCOUNT}-${routeConst.PAYMENT_METHODS}`,
        component: () => import('@/views/account-2023/payment-methods/ManagePaymentMethods.vue'),
      },
      {
        path: routeConst.PURCHASE_HISTORY,
        name: `${routeConst.ACCOUNT}-${routeConst.PURCHASE_HISTORY}`,
        component: () => import(('@/views/account-2023/purchase-history/PurchaseHistory.vue')),
      },
      {
        path: routeConst.DOWNLOADS,
        name: `${routeConst.ACCOUNT}-${routeConst.DOWNLOADS}`,
        component: () => import('@/views/account/downloads/Downloads.vue'),
      },
      {
        path: routeConst.PASSWORD,
        name: `${routeConst.ACCOUNT}-${routeConst.PASSWORD}`,
        component: () => import('@/views/account/settings/Password.vue'),
      },
      {
        path: `/:lang?/${routeConst.ACCOUNT}/reseller`,
        name: `${routeConst.ACCOUNT}-reseller`,
        component: () => import('@/views/account/reseller/ChangeReseller.vue'),
      },
    ],
  },
];
