import * as accountApi from '@motionelements/core/src/api/account.js';

const state = {
  // member: null,
  profileCompletion: 0,
};
const getters = {
  profileCompletion: ({ profileCompletion }) => profileCompletion,
};

const mutations = {
  setProfileCompletion(state, number) {
    state.profileCompletion = number;
  },
};

const actions = {
  getAccountProfileCompletion({ commit }) {
    return accountApi.getAccountProfileCompletion().then((response) => {
      commit('setProfileCompletion', response.data.data.completed);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
