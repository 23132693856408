import { getCategoryPage } from '@/api/category.js';
import { searchElements } from '@/api/search.js';
// import notificationService from '@motionelements/core/src/services/notification.service';

const state = {
  categoryData: {
    facets: {},
    suggestions: {},
    page: 1,
    perPage: 10,
    totalCount: 0,
    data: {},
    html: {
      title: '',
      description: '',
      url: '',
    },
    // keywords: [],
    // lightboxes: [],
  },
  backgroundImageUrl: '',
  latest: [],
  popular: [],
  // collections: [],
  searchedElements: [],
  dynamicElementsByMediaType: [],
};
const getters = {
  categoryData: ({ categoryData }) => categoryData,
  title: ({ categoryData }) => _.get(categoryData, 'html.title', categoryData.name),
  description: ({ categoryData }) => _.get(categoryData, 'html.description', ''),
  url: ({ categoryData }) => _.get(categoryData, 'html.url'),
  latest: ({ latest }) => latest,
  popular: ({ popular }) => popular,
  searchedElements: ({ searchedElements }) => searchedElements,
};

const mutations = {
  setCategoryData(state, data) {
    state.categoryData = data;
  },
  setLatest(state, data) {
    state.latest = data;
  },
  setPopular(state, data) {
    state.popular = data;
  },
  setBackgroundImageUrl(state, url) {
    state.backgroundImageUrl = url;
  },
  setSearchedElements(state, data) {
    state.searchedElements = data;
  },
  // setDynamicElementsByMediaType(state, data) {
  //   state.dynamicElementsByMediaType = data;
  // },
};

const actions = {
  getCategoryData({ commit }, { id, mediaType }) {
    return getCategoryPage(id, mediaType).then((response) => {
      const data = response.data.data;
      commit('setCategoryData', data);
      if (data.backgroundImage) {
        // this.backgroundImage = data.backgroundImage.url;
        commit('setBackgroundImageUrl', data.backgroundImage.url);
      }
      return response;
    });
  },
  getSearchElements({ commit }, { mediaType, params }) {
    return searchElements(mediaType, params).then((response) => {
      console.log(response);
      const data = response.data.data;
      if (Array.isArray(data)) {
        if (params.sort === 'popular') {
          commit('setPopular', data);
        } else if (params.sort === 'latest') {
          commit('setLatest', data);
        } else {
          commit('setSearchedElements', data);
        }
      }
      return response;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
