import { getDynamicPage } from '@/api/dlp.js';
import { searchElements } from '@/api/search.js';
// import notificationService from '@motionelements/core/src/services/notification.service';

const state = {
  dlpData: {
    heading: '',
    subheading: '',
    description: '',
    keywords: [],
    lightboxes: [],
  },
  backgroundImageUrl: '',
  elements: [],
  collections: [],
  // searchedElements: [],
  // searchedCollections: [],
  dynamicElementsByMediaType: [],
};

const getters = {
  dlpData: ({ dlpData }) => dlpData,
  backgroundImageUrl: ({ backgroundImageUrl }) => backgroundImageUrl,
  keywords: ({ dlpData }) => dlpData.keywords,
  collections: ({ collections }) => collections,
  elements: ({ elements }) => elements,
  lightboxes: ({ dlpData }) => dlpData.lightboxes,
  mediaTypes: ({ dlpData }) => dlpData.mediaTypes || [],
  heading: ({ dlpData }) => dlpData.heading,
  description: ({ dlpData }) => dlpData.description || dlpData.subheading,
  subheading: ({ dlpData }) => dlpData.subheading,
  // searchedElements: ({ searchedElements }) => searchedElements,
  // searchedCollections: ({ searchedCollections }) => searchedCollections,
  dynamicElementsByMediaType: ({ dynamicElementsByMediaType }) => dynamicElementsByMediaType,
};

const mutations = {
  setDlpData(state, data) {
    state.dlpData = data;
  },
  setBackgroundImageUrl(state, url) {
    state.backgroundImageUrl = url;
  },
  // setSearchedElements(state, data) {
  //   state.searchedElements = data;
  // },
  // setSsearchedCollections(state, data) {
  //   state.searchedCollections = data;
  // },
  setDynamicElementsByMediaType(state, data) {
    // state.dynamicElementsByMediaType = data;
    state.dynamicElementsByMediaType.push(data);
  },
};

const actions = {
  getDlpData({ dispatch, commit }, { id, page }) {
    // return api for PageLoader response
    return getDynamicPage(id, {
      perPage: 96,
      page: page,
    }).then((response) => {
      const data = response.data.data;
      commit('setDlpData', data);
      if (data.backgroundImage) {
        // this.backgroundImage = data.backgroundImage.url;
        commit('setBackgroundImageUrl', data.backgroundImage.url);
      }
      // commit('setDynamicElementsByMediaType', data.mediaTypes);
      const keywords = _.get(data, 'keywords') || '';
      // remove DLP maybe has duplicate mediaTypes
      const dlpMediaTypes = [...new Set(data.mediaTypes)];
      // const dlpMediaTypes = data.mediaTypes;
      console.log('DLP data no duplicate mediaTypes: ', dlpMediaTypes);
      // console.log(keywords);
      if (keywords.length > 1) {
        dlpMediaTypes.forEach((mediaType) => {
          // console.log(mediaType);
          dispatch('searchDlpElementsByMediaTypes', {
            keywords: keywords,
            mediaType: mediaType,
          });
        });
      }
      return response;
    });
  },
  searchDlpElementsByMediaTypes(state, { keywords, mediaType }) {
    // console.log('searchDlpElementsByMediaTypes: ', keywords, mediaType);
    searchElements(mediaType, {
      q: keywords,
      perPage: 12,
    }).then((response) => {
      const elements = response.data.data;
      console.log('keyword elements', elements);
      if (Array.isArray(elements)) {
        // prevent elements no content
        if (elements.length) {
          state.commit('setDynamicElementsByMediaType', {
            mediaType: mediaType,
            elements: elements,
          });
        }
      }
    });
    // const keywords = _.get(dlpData, 'keywords') || '';
    // console.log(keywords);
    // const dynamicElements = [];
    // if (keywords.length > 1) {
    //   dlpData.mediaTypes.forEach((mediaType) => {
    //     console.log(mediaType);
    //     searchElements(mediaType, {
    //       // s: encodeURI(this.pageData.keywords),
    //       s: keywords,
    //       perPage: 12,
    //     }).then((response) => {
    //       console.log('keyword elements', response.data.data);

    //       const elements = response.data.data;
    //       if (Array.isArray(elements)) {
    //         if (!dlpData.mediaTypes.find(x => x.mediaType === mediaType)) {
    //           dynamicElements.push({
    //             mediaType: mediaType,
    //             elements: elements,
    //           });
    //         }
    //       }
    //     });
    //   });
    // }
    // state.commit('setDynamicElementsByMediaType', dynamicElements);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
