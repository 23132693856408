<template>
  <div id="app">
    <component :is="layout"></component>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
import { bootstrapApp } from '@motionelements/core/src/services/app.service.js';

export default {
  name: 'App',
  computed: {
    layout() {
      const defaultLayout = 'default';
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
  beforeCreate() {
    bootstrapApp(this);
  },
  mounted() {
  },
  methods: {
  },
};
</script>
