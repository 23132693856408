/**
 * CHANNEL PAGE ROUTES
 * * ROUTE FILE NAME NEED SAME WITH REAL PATH! EXCEPT HOME
 * NORMAL CHANNEL PAGE: home, video, animation ...
 * FREE PAGE: free/stock-footage, free/gifs, free/premiere-pro-templates
 */
import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import routeConst from './const.js';

export default [
  {
    path: `/:lang?/${routeConst.SIGNUP}`,
    name: routeConst.SIGNUP,
    // eslint-disable-next-line
    component: () => {
      if (featureFlagMixin.methods.isFeatureEnabled('login_signup_redesign_2023')) {
        return import(/* webpackChunkName: "signup" */ '@/views/redesign-2023/signup/SignUp.vue');
      }
      return import(/* webpackChunkName: "signup" */ '@/views/Signup.vue');
    },
    meta: { layout: 'minimal' },
  },
  {
    path: `/:lang?/${routeConst.SIGNUP}/${routeConst.CONTRIBUTOR_STEPS}`,
    name: `${routeConst.SIGNUP}-${routeConst.CONTRIBUTOR_STEPS}`,
    component: () => import(/* webpackChunkName: "signup-artist" */ '@/views/signup/ArtistSteps.vue'),
  },
  {
    path: `/:lang?/${routeConst.SIGNUP}/welcome`,
    name: `${routeConst.SIGNUP}-welcome`,
    component: () => import(/* webpackChunkName: "signup-welcome" */ '@/views/signup/SignUpWelcome.vue'),
  },
  {
    path: `/:lang?/${routeConst.SIGNUP}/contributor-welcome`,
    name: `${routeConst.SIGNUP}-contributor-welcome`,
    component: () => import(/* webpackChunkName: "signup-contributor-welcome" */ '@/views/signup/SignUpWelcomeArtist.vue'),
  },
];
