export default {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    // 'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css',
    // 'https://unpkg.com/kidlat-css/css/kidlat.css',
    // '/vendor/print.210611a.css',
    // '/vendor/print-ja.210611.css',
    // '/vendor/print-ko.210611.css',
    // '/vendor/print-zh-hant.210611.css',
    // need to upload to S3 static.motionelements.com/css for deploy
    // 'https://static.moele.me/css/print.210611a.css', // /vendor/print.210611a.css
    // 'https://moele.me/css/print-ja.210611.css',
    // 'https://moele.me/css/print-ko.210611.css',
    // 'https://moele.me/css/print-zh-hant.210611.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  windowTitle: window.document.title, // override the window title
};
