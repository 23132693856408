import VuexPersistence from 'vuex-persist';

export default new VuexPersistence({
  key: 'me:vuex:payment_methods',
  storage: window.sessionStorage,
  reducer: ({ paymentMethods }) => ({
    // hash: `${user.jwt.id}|${user.language}|${user.currencyCode}|${STORAGE_VUEX_CURRENCY_VERSION}`,
    paymentMethods: {
      canceledToUpdateSubscriptionCard: paymentMethods.canceledToUpdateSubscriptionCard,
    },
  }),
  filter: mutation => [
    'paymentMethods/setCancelToUpdateSubscriptionCard',
  ].includes(mutation.type),
});
