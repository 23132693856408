export default [
    {
        name: 'careers',
        path: '/:lang?/careers',
        component: () => import(
            /* webpackChunkName: "careers-page" */
            '@motionelements/landing-pages/src/modules/careers/CareersPage.vue'
            ),
    },
    {
        name: 'contact',
        path: '/:lang?/contact',
        component: () => import(
            /* webpackChunkName: "contact-page" */
            '@motionelements/landing-pages/src/modules/contact/IndexPage.vue'
            ),
    },
    {
        name: 'partners',
        path: '/:lang?/partners',
        component: () => import(
            /* webpackChunkName: "partners-page" */
            '@motionelements/landing-pages/src/modules/partners/IndexPage.vue'
            ),
    },
    // {
    //   path: '/:lang(ja)/mentor',
    //   component: () => import(
    //     /* webpackChunkName: "mentor-page" */
    //     '@motionelements/landing-pages/src/modules/mentor/IndexPage.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'mentor-index-page',
    //       component: () => import(
    //         /* webpackChunkName: "mentor-intro-page" */
    //         '@motionelements/landing-pages/src/modules/mentor/MentorIntro.vue'),
    //     },
    //     {
    //       path: ':mentorName',
    //       name: 'mentor-detail-page',
    //       component: () => import(
    //           /* webpackChunkName: "mentor-profile-page" */
    //           '@motionelements/landing-pages/src/modules/mentor/MentorProfile.vue'),
    //     },
    //   ],
    // },
];
