import api from '@motionelements/core/src/api/base-api-v2.js';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

// eslint-disable-next-line import/prefer-default-export
export const getCategoryPage = async (id, mediaType) => {
  // params = mt=${mt}
  const params = {
    mediaType: mediaType,
  };
  return api.get(`/v2/categories/${id}`, {
    params: {
      ...api.getLocaleParams(),
      // ...params,
      ...snakeCaseKeys(params),
    },
  });
};
