import * as agreementApi from '@motionelements/core/src/api/agreements.js';

const state = {
  list: [],
};

const getters = {
  agreementsToAccept({ list }) {
    return list.filter(agreement => !agreement.accepted && agreement.accepted !== null);
  },
};

const actions = {
  getList({ commit }) {
    return agreementApi.listAgreements()
      .then(({ data: { data } }) => {
        commit('setList', data);
      });
  },
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
