import api from '@motionelements/core/src/api/base-api-v2';

const baseUrl = '/v2/payment-methods';

export const listAllCards = () => api.get(`${baseUrl}`);

export const createSetupIntent = () => api.request('post', `${baseUrl}/setup-intent`, {
});

// eslint-disable-next-line
export const saveCreditCard = (data) => {
  return api.request('post', baseUrl, {
    data,
    params: api.getLocaleParams(),
  });
};

// eslint-disable-next-line
export const updateCreditCard = (pmId, newPmId) => {
  return api.request('put', `${baseUrl}/${pmId}`, {
    data: {
      paymentMethodId: newPmId,
    },
    params: api.getLocaleParams(),
  });
};

export const deleteCreditCard = paymentMethodId => api.request('delete', `${baseUrl}/${paymentMethodId}`);
