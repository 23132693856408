export default [
  {
    path: '/:lang?/corporate',
    name: 'corporate-layout',
    component: () => import(
      /* webpackChunkName: "corporate-layout" */
      '@/views/organization/Corporate.vue'
    ),
    children: [
      {
        path: 'users',
        name: 'corporate-users-list',
        component: () => import(
          /* webpackChunkName: "corporate-user-list" */
          '@/views/organization/users/List.vue'
        ),
      },
      {
        path: 'transactions',
        name: 'corporate-transactions-list',
        component: () => import(
          /* webpackChunkName: "corporate-transactions-list" */
          '@/views/organization/transactions/List.vue'
        ),
      },
    ],
  },
];
