<template>
  <div id="app">
    <!-- <Alert/> -->
    <NavBar />
    <div id="router-view">
      <FlashNotifications />
      <ToastNotifications />
      <transition :name="transitionName" mode="out-in">
        <router-view />
      </transition>
    </div>
    <ModalWrapper/>
    <Footer/>
    <Blocker v-if="showBlocker">
      <template v-slot:message>
        <p>{{ blockerMessage }}</p>
      </template>
    </Blocker>
    <ShinySvgGradient />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import NavBar from '@motionelements/core/src/components/TheNavBar.vue';
import windowResizeMixin from '@motionelements/core/src/components/mixins/window-resize.mixin.js';
import productEventsMixin from '@motionelements/core/src/components/mixins/product-events.mixin.js';

export default {
  name: 'default',
  components: {
    NavBar,
    Footer: () => import('@motionelements/core/src/components/TheFooter.vue'),
    ShinySvgGradient: () => import('@motionelements/core/src/components/product/ShinySvgGradient.vue'),
    FlashNotifications: () => import('@motionelements/core/src/components/ui/notifications/BaseFlashNotifications.vue'),
    ToastNotifications: () => import('@motionelements/core/src/components/ui/notifications/BaseToastNotifications.vue'),
    ModalWrapper: () => import('@motionelements/core/src/components/TheRootModal.vue'),
    Blocker: () => import('@motionelements/core/src/components/ui/Blocker.vue'),
  },
  mixins: [windowResizeMixin, productEventsMixin],
  data() {
    return {
      transitionName: 'page-no-fade',
    };
  },
  created: function () {
    if (process.env.VUE_APP_PRERENDER === 'false') {
      this.transitionName = 'fade';
    }
  },
  computed: {
    ...mapState({
      showBlocker: state => state.blocker.show,
      blockerMessage: state => state.blocker.message,
    }),
  },
  mounted() {
    // this.$root.$on('updateGoogleTagManager', this.updateGoogleTagManager);
  },
  methods: {
    // updateGoogleTagManager(info) {
    //   console.log('updateGoogleTagManager created : ', info);
    //
    //   let memberType = 'member';
    //   if (info.artist) {
    //     memberType = 'artist';
    //   }
    //   if (info.admin) {
    //     memberType = 'admin';
    //   }
    //   if (info.reseller) {
    //     memberType = 'reseller';
    //   }
    //
    //   this.$gtm.trackEvent({
    //     event: 'vue_root_created',
    //     userId: info.id,
    //     username: info.username,
    //     firstName: info.first_name,
    //     lastName: info.last_name,
    //     fullName: info.full_name,
    //     email: info.email,
    //     memberType: memberType,
    //     industry: info.industry,
    //     language: info.language,
    //     countryCode: info.country,
    //     jobFunction: info.job_function,
    //   });
    // },
  },
  beforeDestroy() {
    // this.$root.$off('updateGoogleTagManager', this.updateGoogleTagManager);
  },
};
</script>

<style lang="scss">
@import "@motionelements/assets/src/scss/me-custom/account-redesign-2023/index.scss";

@import "@motionelements/assets/src/scss/font-load/style-en";
@import "@motionelements/assets/src/scss/font-load/style-ja";
@import "@motionelements/assets/src/scss/font-load/style-ko";
@import "@motionelements/assets/src/scss/font-load/style-zh-hant";
@import "@motionelements/assets/src/scss/style-pages.scss";

// for page transition
.page-fade-enter-active, .page-fade-leave-active {
  position: fixed;
  width: 100%;
  transition: all .2s;
}
.page-fade-enter, .page-fade-leave-to {
  height: auto;
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#router-view {
  @include page-height-without-header;
}

.container {
  @media (min-width: 1440px) and (max-width: 1599px) {
    max-width: 1376px;
  }
}

</style>
