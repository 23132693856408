<template>
  <b-dropdown :variant="`${variant} d-flex align-items-center py-2 ${showAvatar ? 'pl-2' : ''}`" right no-caret menu-class="px-4 py-3 text-sm" @show="emitOnShow">
    <template #button-content>
      <img
        v-if="showAvatar"
        :src="avatarUrl"
        alt=""
        class="rounded-circle nav-avatar mr-2"
        @error="onAvatarUrlError($event)"
      >
      <span class="user-name-overflow">
        {{ displayName }}
      </span>
      <font-awesome-icon :icon="faAngleDown" class=" text-lg ml-2" />
    </template>

    <b-dropdown-item :href="$link('/account').url()">
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faUser" />
      </span>
      {{ $t('header.account') }}
    </b-dropdown-item>
    <b-dropdown-item
      :href="feedbackFormUrl"
      rel="noopener noreferrer nofollow"
      target="_blank"
    >
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faCommentAltLines" />
      </span>
      {{ $t('header.feedback') }}
    </b-dropdown-item>
    <b-dropdown-divider v-if="isContributor || isReseller || isOrganization" />
    <b-dropdown-item v-if="isContributor || isExContributor()" :href="$link('/contributor').url()">
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faMeContributor" />
      </span>
      <div>
        {{ $t('term.contributor') }}
        <div v-if="isExContributor()" class="text-gray-400 text-xs">{{ $t('term.deactivated') }}</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item v-if="isReseller" :href="$link('/reseller').url()">
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faUserVneck" />
      </span>
      {{ $t('term.reseller') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="isOrganization" :href="$link('/corporate/users').url()">
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faBuildings" />
      </span>
      <span class="d-flex flex-column">
        <span>{{ organizationInfo.name }}</span>
        <span class="text-muted text-xs font-weight-semibold">
          {{ $t('header.organisation') }}
        </span>
      </span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <NavTheme linkClass="dropdown-item">
      <template v-slot:icon>
        <span class="menu-icon bg-light mr-2">
          <font-awesome-icon :icon="faMoon" />
        </span>
      </template>
    </NavTheme>
    <b-dropdown-item :href="$link('/account/logout').url()">
      <span class="menu-icon bg-light mr-2">
        <font-awesome-icon :icon="faSignOut" />
      </span>
      {{ $t('header.logout') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  faCommentAltLines,
  faSignOut,
  faAngleDown,
  faMoon,
} from '@fortawesome/pro-light-svg-icons';
import {
  faMeContributor,
} from '@motionelements/assets/src/js/me-font/icons-channels2022';
import {
  faUser,
  faUserVneck,
  faBuildings,
} from '@motionelements/assets/src/js/me-font/icons-fa6';
import { isExContributor } from '@motionelements/core/src/services/account.service.js';

export default {
  name: 'user-nav-dropdown',
  components: {
    NavTheme: () => import('./NavTheme.vue'),
  },
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  data() {
    return {
      faUser,
      faCommentAltLines,
      faSignOut,
      faUserVneck,
      faBuildings,
      faAngleDown,
      faMoon,
      faMeContributor,
      avatarUrlError: false,
    };
  },
  computed: {
    ...mapState({
      member: state => state.user.member,
      isContributor: state => state.user.isArtist,
      isReseller: state => state.user.isReseller,
      isOrganization: state => state.user.isOrganization,
    }),
    ...mapGetters({
      isMember: 'user/isMember',
      avatarUrl: 'user/getAvatarUrl',
      displayName: 'user/getDisplayName',
    }),
    feedbackFormUrl() {
      let url;
      switch (this.$i18n.locale) {
        case 'ja':
          url = 'https://airtable.com/shr03yuytXp66zgjB';
          break;
        case 'ko':
          url = 'https://airtable.com/shrsPi8NgMFcbQFQr';
          break;
        case 'zh-hant':
          url = 'https://airtable.com/shrfvL4rF1upSZxMp';
          break;
        default:
          url = 'https://airtable.com/shrwG9spdLuaahm47';
      }
      url += `?prefill_Email=${encodeURIComponent(this.userEmail)}`;
      return url;
    },
    userEmail() {
      return this.member.email;
    },
    organizationInfo() {
      return this.isMember ? this.member.organization : '';
    },
    showAvatar() {
      return !this.avatarUrlError && this.avatarUrl && !this.avatarUrl.includes('https%3A%2F%2Fstatic.motionelements.com%2Fimg%2Fmember%2F');
    },
  },
  methods: {
    emitOnShow() {
      this.$emit('onShow');
    },
    onAvatarUrlError(event) {
      this.avatarUrlError = true;
      event.onerror = null;
    },
    isExContributor,
  },
};
</script>

<style lang="scss" scoped>
.nav-avatar {
  width: 32px;
  height: 32px;
  margin-top: -2px;
  margin-bottom: -2px;
}
.user-name-overflow {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep {
  .dropdown-item {
    width: 186px;
    display: flex;
    align-items: center;
    padding: map-get($spacers, 2) 0;
    font-weight: 500;
    white-space: normal;
    .menu-icon {
      display: inline-flex;
      flex: 0 0 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    @include hover {
      text-decoration: $link-hover-decoration;
      background-color: transparent !important;
      color: $primary !important;
    }
    &:focus {
      background-color: transparent !important;
    }
  }
}

.show {
  .ui-dark & {
    ::v-deep {
      > .btn-simple-secondary.dropdown-toggle {
        color: $gray-100;
      }
    }
  }
}
</style>
