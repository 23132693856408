const getters = {
  getList(state) {
    return state.list;
  },
  getDocument(state) {
    return state.document;
  },
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
  setDocument(state, document) {
    state.document = document;
  },
};

export default {
  namespaced: true,
  state: () => ({
    list: null,
    document: null,
  }),
  mutations,
  getters,
};
