import api from '@motionelements/core/src/api/base-api-v2.js';

// eslint-disable-next-line
export const getCoupon = (couponId, axiosConfig) => {
  return api.get(`/v2/coupons/${couponId}`, {
    ...(axiosConfig || {}),
  });
};

// eslint-disable-next-line
export const redeemCoupon = (couponId, axiosConfig) => {
  return api.request('post', `/v2/coupons/${couponId}/redeem`, {
    ...(axiosConfig || {}),
  });
};
