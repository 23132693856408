// import api from '@motionelements/core/src/api/base-api-v2';
import Vue from 'vue';
import loadingMutation from '@motionelements/core/src/store/mutations-mixins/loadingMutation.js';
import * as mediaTypesApi from '@motionelements/core/src/api/media-types.js';
import * as artistYCIDApi from '@motionelements/core/src/api/content-id-agencies.js';

const state = {
  loading: false,
  isError: false,
  //
  allAgencies: [],
  agencies: [],
  //
  other: '',
  //
  vError: null,
};

const getters = {
  agenciesSorted(state) {
    try {
      return state.agencies
        .sort((a, b) => (a.name.localeCompare(b.name)))
        .sort((a, b) => {
          const fa = a.id === 'self_managed' ? 1 : 0;
          const fb = b.id === 'self_managed' ? 1 : 0;
          return fb - fa;
        })
        .sort((a, b) => {
          const fa = a.id === 'other' ? 1 : 0;
          const fb = b.id === 'other' ? 1 : 0;
          return fa - fb;
        })
        .sort((a, b) => (b.default - a.default));
    } catch (err) {
      console.dir(err);
    }
  },
  defaultAgency(state) {
    return state.agencies.find(x => x.default);
  },
};

const actions = {
  LIST_ALL_AGENCIES({ commit }) {
    return mediaTypesApi.listYCIDAgencies('music')
      .then((response) => {
        commit('setAllAgencies', response.data.data);
      });
  },
  LIST_AGENCIES({ commit }) {
    commit('setIsError', false);
    commit('setLoading', true);
    return artistYCIDApi.listArtistYCIDAgencies()
      .then((response) => {
        response.data.data.forEach(x => {
          x.loading = false;
        });
        commit('setAgencies', response.data.data);
      })
      .catch(err => {
        console.error(err);
        commit('setIsError', true);
        return Promise.reject(err);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  ADD_AGENCY({ commit }, agency) {
    return artistYCIDApi.addArtistYCIDAgency({ ids: [agency.id] })
      .then((response) => {
        console.log(response);
        const status = _.get(response, 'status');
        if (status === 201) {
          let addedAgency = _.get(response, 'data.data[0]');
          if (addedAgency) {
            addedAgency = { ...addedAgency, loading: true };
            commit('addAgency', addedAgency);
            commit('setAgencyAsLoaded', addedAgency.id);
          }
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        commit('removeAgency', agency.id);
        return Promise.reject(err);
      });
  },
  ADD_OTHER_AGENCY({ commit }, agency) {
    commit('updateAgencyLoadingStatus', { id: 'other', loading: true });
    return artistYCIDApi.addArtistYCIDAgency({ ids: [agency.id] })
      .then((response) => {
        const status = _.get(response, 'status');
        if (status === 201) {
          let addedAgency = _.get(response, 'data.data[0]');
          if (addedAgency) {
            addedAgency = { ...addedAgency, loading: true };
            commit('addAgency', addedAgency);
            commit('setAgencyAsLoaded', addedAgency.id);
            commit('setOther', '');
            commit('removeAgency', 'other');
          }
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject(err);
      })
      .finally(() => {
        commit('updateAgencyLoadingStatus', { id: 'other', loading: false });
      });
  },
  DELETE_AGENCY(store, id) {
    return artistYCIDApi.deleteArtistYCIDAgency(id);
  },
  SET_DEFAULT_AGENCY({ commit }, id) {
    commit('updateAgencyLoadingStatus', { id, loading: true });
    return artistYCIDApi.setDefaultArtistYCIDAgency(id)
      .then((response) => {
        console.log(response);
        commit('updateAgencyLoadingStatus', { id, loading: false });
        commit('setDefaultAgency', id);
        return response;
      })
      .catch((err) => {
        console.error(err);
        commit('updateAgencyLoadingStatus', { id, loading: false });
        return Promise.reject(err);
      });
  },
};

const mutations = {
  setAllAgencies(state, agencies) {
    state.allAgencies = agencies;
  },
  setAgencies(state, agencies) {
    state.agencies = agencies;
  },
  addAgency(state, agency) {
    const index = state.agencies.findIndex(x => x.id === _.get(agency, 'id'));
    if (index < 0) {
      const addedAgency = { ...agency, default: false, loading: true };
      state.agencies.push(addedAgency);
    }
    if (agency && index > -1) {
      state.agencies.splice(index, 1, agency);
    }
  },
  setAgencyAsLoaded(state, id) {
    const index = state.agencies.findIndex(x => x.id === id);
    if (index > -1) {
      Vue.set(state.agencies[index], 'loading', false);
    }
  },
  updateAgencyLoadingStatus(state, { id, loading }) {
    const index = state.agencies.findIndex(x => x.id === id);
    console.log(index, loading);
    if (index > -1) {
      Vue.set(state.agencies[index], 'loading', loading);
    }
  },
  setDefaultAgency(state, agencyId) {
    const targetAgency = state.agencies.find(x => x.id === agencyId);
    if (targetAgency) {
      state.agencies.forEach(x => {
        x.default = false;
      });
      targetAgency.default = true;
    }
  },
  removeAgency(state, agencyId) {
    const index = state.agencies.findIndex(x => x.id === agencyId);
    if (index > -1) state.agencies.splice(index, 1);
  },
  resetAgencies(state) {
    state.agencies = [];
    state.other = '';
    state.vError = null;
  },
  setIsError(state, boolean) {
    state.isError = boolean;
  },
  setOther(state, string) {
    state.other = string;
  },
  //
  setVError(state, boolean) {
    state.vError = boolean;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, ...loadingMutation },
};
