import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';
import createMutationsSharer from 'vuex-shared-mutations';

import vuexSharedMutations from '@motionelements/core/src/helpers/vuexSharedMutations.js';
import app from '@motionelements/core/src/store/modules/app.js'; // migrate site to app
import browser from '@motionelements/core/src/store/modules/browser.js';
import user from '@motionelements/core/src/store/modules/user.js';
import subscriptions from '@motionelements/core/src/store/modules/subscriptions.js';
import route from '@motionelements/core/src/store/modules/route.js';
import cart from '@motionelements/core/src/store/modules/cart.js';
import notification from '@motionelements/core/src/store/modules/notification.js';
import modal from '@motionelements/core/src/store/modules/modal.js';
import cookie from '@motionelements/core/src/store/modules/cookie.js';
import blocker from '@motionelements/core/src/store/modules/blocker.js';
import favorites from '@motionelements/core/src/store/modules/favorites.js'; // display favorites
import searchBox from '@motionelements/core/src/store/modules/search-box.js';
import audioPlayer from '@motionelements/core/src/store/modules/audioPlayer.js';
import paymentMethods from '@motionelements/core/src/store/modules/payment-methods.js';
import experiment from '@motionelements/core/src/store/modules/experiment.js';
import payout from '@motionelements/core/src/store/modules/payout.js';
import agencies from '@motionelements/core/src/store/modules/agencies.js';
import apiValidationErrors from '@motionelements/core/src/store/modules/api-validation-errors.js';

// VUEX PERSISTENCE
import {
  persistAccount,
  persistCurrency,
  persistExperiment,
  persistNavbar,
  persistPaymentMethods,
  persistCart,
  persistFavorites,
} from '@/store/persists';

import agreements from '@/store/modules/agreements.js';
import dashboard from './modules/dashboard.js';
import legal from './modules/legal.js';
import purchaseHistory from './modules/account/purchase-history.js';
import dlp from './modules/dlp.js';
import category from './modules/category.js';
import corporateUsers from './modules/corporate/users.js';
import corporateTransactions from './modules/corporate/transactions.js';
import coupon from './modules/coupon.js';

const mutationSharer = createMutationsSharer({
  predicate: [
    'user/setJwtId',
    'user/setMember',
    'user/setLanguage',
    'user/setCurrencies',
    // paymentMethods
    'paymentMethods/setCancelToUpdateSubscriptionCard',
    ...vuexSharedMutations,
  ],
});

const isProduction = process.env.VUE_APP_ENV === 'production';

let plugins = [
  mutationSharer,
  persistExperiment.plugin,
  persistNavbar.plugin,
  persistCart.plugin,
  persistFavorites.plugin,
];

if (!isProduction) {
  plugins = [...plugins, ...[
    // additional plugins for staging / dev
    persistAccount.plugin,
    persistCurrency.plugin,
    persistPaymentMethods.plugin,
  ]];
}

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    // CORE modules
    app,
    browser,
    cookie,
    experiment,
    modal,
    notification,
    user,
    payout,
    subscriptions,
    agencies,
    apiValidationErrors,

    // OTHER modules
    cart,
    audioPlayer,
    dashboard,
    route,
    favorites,
    searchBox,
    legal,
    blocker,
    purchaseHistory,
    dlp,
    category,
    corporateUsers,
    corporateTransactions,
    agreements,
    paymentMethods,
    coupon,
  },
  // toggle vue persist on and off
  plugins: plugins,
});
