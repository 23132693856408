<template>
  <div class="recently-visited custom-scroll">
    <ul class="navbar-nav flex-row align-items-center">
      <li v-for="channelType in recentlyVisitedList" :key="channelType.id">
        <NavLink
          :to="channelType.link"
          class="px-3 nav-link cjk-word-break"
          v-html="getChannelTypeName(channelType.id)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'RecentlyVisitedList',
  props: {
    showItems: {
      type: Number,
      default: 3,
    },
  },
  mixins: [channelTypesMixin],
  components: {
    NavLink: () => import('@motionelements/core/src/components/nav/NavLink.vue'),
  },
  watch: {
    '$route'() {
      // console.log('========== WATCH $route ', val, oldVal);
      this.updateRecentlyVisited();
    },
  },
  computed: {
    ...mapState({
      recentlyVisited: state => state.app.navbarRecentlyVisited,
    }),
    recentlyVisitedList() {
      return [...this.recentlyVisited].splice(0, this.showItems);
    },
  },
  methods: {
    updateRecentlyVisited() {
      let channel;
      if (_.get(this.$route.params, 'channel')) { // channel page
        channel = this.channelTypesFiltered.find(x => x.link === `/${_.get(this.$route.params, 'channel')}`);
      }
      if (_.get(this.$route.params, 'mediatype')) { // search page
        channel = this.channelTypesFiltered.find(x => x.id === _.get(this.$route.params, 'mediatype'));
      }
      if (channel) this.$store.commit('app/addNavbarRecentlyVisited', channel);
    },
  },
  mounted() {
    this.updateRecentlyVisited();
  },
};
</script>

<style lang="scss" scoped>
.recently-visited {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  .nav-link {
    white-space: nowrap;
  }
}

.custom-scroll {
  overflow: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
