const state = {
  errors: [],
};

const getters = {
  getApiValidationErrorMessage(state) {
    // eslint-disable-next-line
    return (name) => {
      const error = state.errors.find(({ pointer }) => _.camelCase(pointer) === name);
      if (error) return _.get(error, 'detail');
    };
  },
};

const mutations = {
  setErrors(state, errorResponse) {
    let { data: { errors } } = errorResponse;
    errors = errors.map((error) => {
      const detail = _.get(error, 'detail');
      const pointer = _.get(error, 'source.pointer');
      return detail && pointer ? { detail, pointer } : false;
    });
    if (errors) state.errors = errors;
  },
  clearErrors(state) {
    state.errors = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
