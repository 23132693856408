import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languages } from '@/lang';

const defaultLocale = 'en';
const messages = Object.assign(languages);

Vue.use(VueI18n);

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});
