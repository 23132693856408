import api from '@motionelements/core/src/api/base-api-v2';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

const baseUrl = '/v2/users';

export const listUsers = async (params, axiosConfig) => {
  params = params || {};

  const queryColumns = {};
  queryColumns['fields[member]'] = params['fields[member]'];
  delete params['fields[member]'];

  const queryParams = params;

  const config = {
    params: {
      ...api.getLocaleParams(),
      ...queryColumns,
      ...snakeCaseKeys(queryParams),
    },
    ...(axiosConfig || {}),
  };
  return api.get(baseUrl, config);
};

export const createUser = async (params) => {
  const config = {
    data: {
      ...params,
    },
    params: {
      ...api.getLocaleParams(),
    },
    storeValidationErrors: true,
  };
  return api.request('post', `${baseUrl}`, config);
};

export const updateUser = async (userId, params) => {
  if (!userId) return false;

  const config = {
    data: {
      ...params.payload,
    },
    params: {
      ...api.getLocaleParams(),
    },
    storeValidationErrors: true,
  };

  return api.request('patch', `${baseUrl}/${userId}`, config);
};

export const deleteUser = async (userId) => {
  if (!userId) return false;

  const config = {
    params: {
      ...api.getLocaleParams(),
    },
  };

  return api.request('delete', `${baseUrl}/${userId}`, config);
};


export const allocateCredits = async (userId, params) => {
  if (!userId) return false;

  const config = {
    params: {
      ...api.getLocaleParams(),
    },
    data: {
      ...snakeCaseKeys(params),
    },
  };

  return api.request('post', `${baseUrl}/${userId}/allocate-credits`, config);
};


export const sendActivationEmail = async (userId) => {
  if (!userId) return false;

  const config = {
    params: {
      ...api.getLocaleParams(),
    },
  };

  return api.request('post', `${baseUrl}/${userId}/send-activation-email`, config);
};
