<template>
  <header class="header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="flex-grow-1">
          <NavLogo class="mr-3 mr-lg-4 px-0" />
        </div>
        <MenuLanguageNavDropdown class="d-md-block d-none" />
        <button
          v-b-toggle.menu-lang-nav-mobile
          class="btn btn-custom-link px-2 d-block d-md-none"
          data-qa="home-page-nav-lang-btn-mobile"
        >
          <font-awesome-icon :icon="faMeEarth" class="text-2xl align-top" />
        </button>
      </div>
    </div>
    <CustomSidebar id="menu-lang-nav-mobile" :title="$t('header.select_language')" class="d-block d-md-none">
      <LanguagesList />
    </CustomSidebar>
  </header>
</template>

<script>
import { faMeEarth } from '@motionelements/assets/src/js/me-font/icons-channels2022';

import NavLogo from './NavLogo.vue';
import MenuLanguageNavDropdown from './MenuLanguageNavDropdown.vue';

export default {
  name: 'TheNavBarMinimal',
  components: {
    NavLogo,
    MenuLanguageNavDropdown,
    LanguagesList: () => import('@motionelements/core/src/components/modules/common/MenuLanguagesList.vue'),
    CustomSidebar: () => import('./CustomSidebar.vue'),
  },
  data() {
    return {
      faMeEarth,
    };
  },
};
</script>
