import axios from 'axios';
import Vue from 'vue';
import * as ordersApi from '@/api/orders.js';

const state = {
  // from query string in url (camelCase)
  query: {
  },
  filters: {
  },
  filtersList: [
    'type',
    'paymentMethod',
    'status',
    'payment_method',
    'per_page',
  ],

  orders: [],

  pagination: {
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },

  cancelTokens: {},
};

function updateStateOrders(state, updatedOrders) {
  if (!Array.isArray(updatedOrders)) {
    return false;
  }

  // if elements is still displayed
  if (state.orders && state.orders.length) {
    updatedOrders.forEach((order) => {
      const index = state.orders.findIndex(x => x.id === order.id);

      if (index !== -1) {
        // update vuex with published elements
        // set reactive array
        // https://stackoverflow.com/questions/53794155/vue-js-add-or-set-new-data-to-object-in-store
        Vue.set(state.orders, index, { ...state.orders[index], ...order });
      }
    });
  }
}

const getters = {
  orders: state => state.orders,

  keyword: state => _.get(state.query, 'q'),
  filtersList: state => state.filtersList,
  selectedFiltersCount: state => _.size(_.omitBy(state.filters, _.isNil)),
  hasSelectedFilters: (state, getters) => getters.selectedFiltersCount > 0,

  perPage: state => state.pagination.perPage,
  currentPage: state => state.pagination.currentPage,
  totalCount: state => state.pagination.totalCount,
};

function refreshCancelToken(state, tokenName) {
  // cancel previous request
  if (_.has(state.cancelTokens, tokenName)) {
    const info = {
      type: 'cancelToken',
      name: `${tokenName}`,
    };
    state.cancelTokens[tokenName].cancel(info);
  }

  // create cancel token
  state.cancelTokens[tokenName] = axios.CancelToken.source();

  return state.cancelTokens[tokenName];
}

const actions = {

  listOrders({ commit }, params) {
    const cancelToken = refreshCancelToken(state, 'listOrders');
    const axiosConfig = {
      cancelToken: cancelToken.token,
    };

    return ordersApi.listOrders(params, axiosConfig)
    // return ordersApi.listOrdersMock(params, axiosConfig)
      .then((response) => {
        commit('setOrders', response.data.data);
        commit('setPagination', response.data);
        return response;
      });
  },

  updateOrder({ state }, { orderId, data }) {
    return ordersApi.updateOrder(orderId, data).then((response) => {
      // open licensee immediately after update order
      // this.openLicensee();
      const data = response.data.data;
      console.log(data);
      // set the data into orders
      // update order list item
      updateStateOrders(state, [data]);
      // this.updateStoreOrderListName();
      // this.$store.dispatch('modal/hide');
      return data;
    });
  },

  setQuery({ commit }, query) {
    const filters = {};

    if (query.type) {
      filters.type = query.type;
    }

    if (query.status) {
      filters.status = query.status;
    }

    if (query.paymentMethod) {
      filters.paymentMethod = query.paymentMethod;
    }

    commit('setFilters', filters);
    commit('setQuery', query);
  },
};

const mutations = {
  setOrders(state, list) {
    state.orders = list;
  },

  setQuery(state, query) {
    console.log('setQuery', query);
    state.query = query;
  },

  setFilters(state, filters) {
    state.filters = filters;
  },

  setElements(state, data) {
    state.elements = data;
  },

  setCurrentMediaType(state, mediaType) {
    state.currentMediaType = mediaType;
  },

  // pagination
  setCurrentPage(state, number) {
    state.pagination.currentPage = parseInt(number, 10);
  },

  setPerPage(state, number) {
    state.pagination.perPage = parseInt(number, 10);
  },

  setPagination(state, data) {
    state.pagination.currentPage = data.page;
    state.pagination.perPage = data.perPage;
    state.pagination.itemPerPage = data.perPage;
    state.pagination.totalCount = data.totalCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
