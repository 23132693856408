/**
 * CATEGORY PAGE ROUTES
 * * ROUTE FILE NAME NEED SAME WITH REAL PATH! EXCEPT HOME
 * NORMAL CHANNEL PAGE: home, video, animation ...
 * CATEGORY PAGE: after-effects/{some-slug-from-title}-{id}
 * after-effects/slideshow-templates-199
 */
import routeConst from './const.js';

const categoryAndDlpPageRoutes = [
  routeConst.AE,
  routeConst.PR,
  routeConst.ANIMATION,
  routeConst.VIDEO,
  routeConst.MUSIC,
  routeConst.THREE_D,
  routeConst.SFX,
  routeConst.PR,
  routeConst.IMAGE,
  routeConst.MOTION,
  routeConst.RESOLVE,
  routeConst.LOTTIE,
  routeConst.UI,
  routeConst.PRESENTATION,
  routeConst.GRAPHIC,
  routeConst.MIDJORNEY,
  routeConst.PROMPT,
];
//  mediatype base regex
// const mediaTypeRegex = 'after-effects|animation|video|music|3d|sound-effects|premiere-pro|image|apple-motion|davinci-resolve|lottie';
const mediaTypeRegex = categoryAndDlpPageRoutes.toString().split(',').join('|');
// regex match category address
// const mediatypeRegex = '[a-zA-Z-]*-[0-9]*';
// regex match dlp address
// const dlpRegex = '[a-zA-Z-]*-d[0-9]*';

const languageRegex = process.env.VUE_APP_LANGUAGE_CODES.split(',').join('|');

export default [
  {
    path: `/:lang(${languageRegex})?/:mediaTypePath(${mediaTypeRegex})/:slug-:id(\\d+)`,
    name: `${routeConst.CATEGORY}`,
    component: () => import(/* webpackChunkName: "category-page" */ '@/views/category/CategoryPageLoader.vue'),
  },
  {
    path: `/:lang(${languageRegex})?/:mediaTypePath(${mediaTypeRegex})/:slug-d:id(\\d+)`,
    name: `${routeConst.DLP}`,
    component: () => import(/* webpackChunkName: "dynamic-landing-page" */ '@/views/dlp/DlpLoader.vue'),
  },
  {
    path: `/:lang(${languageRegex})?/:mediaTypePath(${mediaTypeRegex})/:d:id(\\d+)`,
    name: `${routeConst.DLP}d`,
    component: () => import(/* webpackChunkName: "dynamic-landing-page" */ '@/views/dlp/DlpLoader.vue'),
  },
];
