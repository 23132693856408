// import { compareAsc } from 'date-fns';
// import notificationService from '@motionelements/core/src/services/notification.service.js';
import loadingMutation from '@motionelements/core/src/store/mutations-mixins/loadingMutation.js';
import * as couponApi from '@/api/coupon.js';

const state = {
  coupon: null,
  //
  loading: false,
};

const getters = {
  couponId({ coupon }) {
    return _.get(coupon, 'couponId');
  },
  couponStatus({ coupon }) {
    if (!coupon) return 'not_found';
    return _.get(coupon, 'status');
  },
  couponCredits({ coupon }) {
    return _.get(coupon, 'credits');
  },
  couponDiscount({ coupon }) {
    return _.get(coupon, 'discount');
  },
  couponPromo({ coupon }) {
    return _.get(coupon, 'promotion', {});
  },
};

const actions = {
  getCoupon({ commit }, couponId) {
    commit('setLoading', true);
    return couponApi.getCoupon(couponId)
      .then((response) => {
        // response.data.data.status = 'expired';
        commit('setCoupon', response.data.data);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  redeemCoupon({ commit }, couponId) {
    return couponApi.redeemCoupon(couponId)
      .then((response) => {
        commit('setCoupon', response.data.data);
        // notificationService.alert({
        //   level: 'success',
        //   title: 'You have successfully used a coupon',
        // });
        return response;
      });
  },
};

const mutations = {
  setCoupon(state, coupon) {
    state.coupon = coupon;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, ...loadingMutation },
};
