/**
 * ORDER ROUTES
 */

export default [
  {
    path: '/:lang?/order/refund',
    name: 'order-refund',
    component: () => import(/* webpackChunkName: "order-refund" */ '@/views/order/Refund.vue'),
  },
];
