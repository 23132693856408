import api from '@motionelements/core/src/api/base-api-v2';

const baseUrl = '/v2/artist/content-id-agencies';

// eslint-disable-next-line
export const listArtistYCIDAgencies = (params) => {
  return api.get(baseUrl, {
    params: {
      ...params, // { page, per_page, q, sort }
      language: _.get(api.getLocaleParams(), 'language'),
      type: 'youtube_content_id',
    },
  });
};

// eslint-disable-next-line
export const setDefaultArtistYCIDAgency = (id) => {
  return api.request('patch', `${baseUrl}/${id}`, {
    params: {
      language: _.get(api.getLocaleParams(), 'language'),
    },
    data: {
      default: 'true',
    },
  });
};

// eslint-disable-next-line
export const getArtistYCIDAgency = (id) => {
  return api.get(`${baseUrl}/${id}`, {
    params: {
      language: _.get(api.getLocaleParams(), 'language'),
    },
  });
};

// eslint-disable-next-line
export const addArtistYCIDAgency = (data) => {
  return api.request('post', baseUrl, {
    data, // ids[]
  });
};

// eslint-disable-next-line
export const deleteArtistYCIDAgency = (id) => {
  return api.request('delete', `${baseUrl}/${id}`, {
    params: {
      language: _.get(api.getLocaleParams(), 'language'),
    },
  });
};
