import { library } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

import {
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBars as falBars,
  faBuilding as falBuilding,
  faChartNetwork as falChartNetwork,
  faCheckCircle as falCheckCircle,
  faCog as falCog,
  faCreditCard as falCreditCard,
  faFilePdf as falFilePdf,
  faFileAlt as falFileAlt,
  faSearch as falSearch,
  faTrashAlt as falTrashAlt,
  faUpload as falUpload,
  faUser as falUser,
  faUsers as falUsers,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCalendarAlt as farCalendarAlt,
  faCommentAltSmile as farCommentAltSmile,
  faEllipsisV as farEllipsisV,
  faFileCertificate as farFileCertificate,
  faLink as farLink,
  faMapMarkerAlt as farMapMarkerAlt,
  faPhotoVideo as farPhotoVideo,
  faPlusCircle as farPlusCircle,
  faMinusCircle as farMinusCircle,
  faPrint as farPrint,
  faRandom as farRandom,
  faRepeatAlt as farRepeatAlt,
  faTachometerAltFastest as farTachometerAltFastest,
  faUsdCircle as farUsdCircle,
  faUser as farUser,
  faEnvelope as farEnvelope,
  faIdCardAlt as farIdCardAlt,
  faExternalLinkAlt as farExternalLinkAlt,
  faPencil as farPencil,
  faTrashAlt as farTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChartNetwork as fasChartNetwork,
  faCheckCircle as fasCheckCircle,
  faBuilding as fasBuilding,
  faCode as fasCode,
  faCreditCard as fasCreditCard,
  faKey as fasKey,
  faQuestion as fasQuestion,
  faRepeat as fasRepeat,
  faSquare as fasSquare,
  faStar as fasStar,
  faUserPlus as fasUserPlus,
  faUsers as fasUsers,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faMeFile3D as falMeFile3D,
  faMeFile3Ds as falMeFile3Ds,
  faMeFileBlend as falMeFileBlend,
  faMeFileC4D as falMeFileC4D,
  faMeFileConvert as falMeFileConvert,
  faMeFileDae as falMeFileDae,
  faMeFileFbx as falMeFileFbx,
  faMeFileMa as falMeFileMa,
  faMeFileObj as falMeFileObj,
  faMeLightning as falMeLightning,
} from '@motionelements/assets/src/js/me-font/icons/me-font-icons-light.js';
// import {
// } from '@motionelements/assets/src/js/me-font/icons/me-font-icons-regular.js';
// import {
// } from '@motionelements/assets/src/js/me-font/icons/me-font-icons-solid.js';

import {
  faUserTimes as fadUserTimes,
  faPaperPlane as fadPaperplane,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faInstagram,
  falBars,
  falBuilding,
  falChartNetwork,
  falCheckCircle,
  falCog,
  falCreditCard,
  falFilePdf,
  falFileAlt,
  falSearch,
  falTrashAlt,
  falUpload,
  falUser,
  falUsers,
  farCalendarAlt,
  farCommentAltSmile,
  farEllipsisV,
  farFileCertificate,
  farLink,
  farMapMarkerAlt,
  farPhotoVideo,
  farPlusCircle,
  farMinusCircle,
  farPrint,
  farRandom,
  farRepeatAlt,
  farTachometerAltFastest,
  farUsdCircle,
  farUser,
  farEnvelope,
  farIdCardAlt,
  farExternalLinkAlt,
  farPencil,
  farTrashAlt,
  fasBuilding,
  fasChartNetwork,
  fasCheckCircle,
  fasCode,
  fasCreditCard,
  fasKey,
  fasQuestion,
  fasRepeat,
  fasSquare,
  fasStar,
  fasUserPlus,
  fasUsers,
  falMeFile3D,
  falMeFile3Ds,
  falMeFileBlend,
  falMeFileC4D,
  falMeFileConvert,
  falMeFileDae,
  falMeFileFbx,
  falMeFileMa,
  falMeFileObj,
  falMeLightning,
  fadUserTimes,
  fadPaperplane,
);

export default function install(Vue) {
  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('font-awesome-layers', FontAwesomeLayers);
  Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
}
