export default (path) => {
  try {
    const match = path.match(/^\/([a-z]{2}(-[a-z]{4})?)\//i);
    const language = match ? match[1] : 'en';
    console.log(process.env.VUE_APP_LANGUAGE_CODES);
    console.log('======== LANGUAGE FROM URL ', language);
    return process.env.VUE_APP_LANGUAGE_CODES.includes(language) ? language : 'en';
  } catch (err) {
    console.error(err);
  }
};
