<template>
  <div id="embed" class="isEmbed" :class="{ 'bg-white': isFeatureEnabled('login_signup_redesign_2023') }">
    <FlashMessage />
    <FlashNotifications />
    <ToastNotifications />

    <div class="page-wrapper d-flex flex-column justify-content-between">
      <TheNavBarMinimal />
      <BackButton v-if="isLoginSignupRedesign" style="margin-bottom: -1.5rem" />
      <div
        class="container my-5"
        :class="loginSignUpRedesignClass"
      >
        <transition name="pagefade" >
          <router-view class="w-100"></router-view>
        </transition>
      </div>

      <MinimalLayoutFooter2023 v-if="isFeatureEnabled('login_signup_redesign_2023')" />
      <MinimalLayoutFooter v-else />
    </div>

    <ShinySvgGradient />
  </div>
</template>
<script>
import TheNavBarMinimal from '@motionelements/core/src/components/nav/TheNavBarMinimal.vue';
import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

// ONLY USED FOR login, @todo check if still needed?
import FlashMessage from '@/components/modules/common/FlashMessage.vue';

export default {
  name: 'minimal-layout',
  components: {
    FlashMessage,
    TheNavBarMinimal,
    ShinySvgGradient: () => import('@motionelements/core/src/components/product/ShinySvgGradient.vue'),
    FlashNotifications: () => import('@motionelements/core/src/components/ui/notifications/BaseFlashNotifications.vue'),
    ToastNotifications: () => import('@motionelements/core/src/components/ui/notifications/BaseToastNotifications.vue'),
    MinimalLayoutFooter: () => import('@/layouts/MinimalLayoutFooter.vue'),
    MinimalLayoutFooter2023: () => import('@/views/redesign-2023/MinimalLayoutFooter.vue'),
    BackButton: () => import('@/views/redesign-2023/BackButton.vue'),
  },
  mixins: [featureFlagMixin],
  computed: {
    isLoginSignupRedesign() {
      return this.isFeatureEnabled('login_signup_redesign_2023') && ['login', 'signup'].includes(this.$route.name);
    },
    loginSignUpRedesignClass() {
      return this.isLoginSignupRedesign ? 'flex-grow-1 d-flex justify-content-center' : '';
    },
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style lang="scss" scoped>
.ui-dark {
  .bg-white {
    background-color: $black !important;
  }
}
.page-wrapper {
  min-height: 100vh;
}
</style>
