export default [
    {
        path: '/:lang?/developer',
        name: 'developer-console',
        component: () => import(
            /* webpackChunkName: "developer-console" */
            '@/views/developer-console/DeveloperConsole.vue'
            ),
    },
];
