// import { getAccountProfileDetails } from '@motionelements/core/src/services/account.service.js';
import axios from 'axios';
import * as ordersApi from '@/api/orders.js';

// import Vue from 'vue';

const state = {

  transactions: {
    loading: {
      isBusy: false,
      isLoading: false,
      isError: false,
    },
    // from query string in url (camelCase)
    query: {
    },
    filters: {
    },
    querys: {
      q: '',
      page: 1,
    },
    filtersList: [
      'buyer',
    ],
    list: [],
    pagination: {
      totalCount: 0,
      currentPage: 1,
      perPage: 5,
    },
  },

  cancelTokens: {},
};

const getters = {
  keyword: state => _.get(state.query, 'q'),

  filtersList: state => state.filtersList,
  hasSelectedFilters: state => _.size(_.omitBy(state.transactions.filters, _.isNil)) > 0,

  transactions: state => state.transactions,
  transactionsTotalCount: state => state.transactions.pagination.totalCount,
  transactionsCurrentPage: state => state.transactions.pagination.currentPage,
  transactionsPerPage: state => state.transactions.pagination.perPage,
};

function refreshCancelToken(state, tokenName) {
  // cancel previous request
  if (_.has(state.cancelTokens, tokenName)) {
    const info = {
      type: 'cancelToken',
      name: `${tokenName}`,
    };
    state.cancelTokens[tokenName].cancel(info);
  }

  // create cancel token
  state.cancelTokens[tokenName] = axios.CancelToken.source();

  return state.cancelTokens[tokenName];
}

const actions = {
  listTransactions({ commit }, params) {
    console.log('listTransactions actions', params);

    const cancelToken = refreshCancelToken(state, 'listTransactions');
    const axiosConfig = { cancelToken: cancelToken.token };

    return ordersApi.listOrders(params, axiosConfig)
      .then((response) => {
        console.log('listOrders actions', params);

        commit('setTransactionsList', response.data.data);
        commit('setTransactionsPagination', response.data);
        return response;
      });
  },
  updateTransaction({ commit, state }, { orderId, data }) {
    return ordersApi.updateOrder(orderId, data).then((response) => {
      console.log('updateTransaction actions done', response);

      const updateRow = response.data.data;

      // hot reload after update
      if (state.transactions.list && state.transactions.list.length) {
        const updateTransaction = [...state.transactions.list];

        const index = state.transactions.list.findIndex(x => x.id === updateRow.id);
        if (index !== -1) {
          updateTransaction[index] = updateRow;
        }

        commit('setTransactionsList', updateTransaction);
      }
    });
  },

  // applyTemplate({ state }, { templateId, skus }) {
  //   return elementsApi.applyTemplate(templateId, skus)
  //     .then((response) => {
  //       console.log('applyTemplate', response);
  //       const elements = response.data.data;
  //       updateStateElements(state, elements);
  //       return response;
  //     });
  // },

  setQuery({ commit }, query) {
    const filters = {};

    if (query.buyer) {
      filters.buyer = query.buyer;
    }

    commit('setFilters', filters);
    commit('setQuery', query);
  },

  setFilters({ commit }, filters) {
    commit('setFilters', filters);
  },

};

const mutations = {
  setTransactionsList(state, list) {
    state.transactions.list = list;
  },

  setTransactionsPagination(state, data) {
    state.transactions.pagination.currentPage = data.page;
    state.transactions.pagination.perPage = data.perPage;
    state.transactions.pagination.totalCount = data.totalCount;
  },

  setQuery(state, query) {
    state.transactions.query = query;
  },

  setFilters(state, filters) {
    state.transactions.filters = filters;
  },
  updateTransaction(state, order) {
    const index = state.transactions.list.findIndex(({ id }) => id === order.id);
    if (index > -1) state.transactions.list.splice(index, 1, order);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
