import routeConst from './const.js';

const channelsCategoriesRoutes = [
  routeConst.VIDEO,
  routeConst.MUSIC,
  routeConst.AE_TEMPLATES,
  routeConst.PR_TEMPLATES,
  routeConst.MOTION_TEMPLATES,
  routeConst.STOCK_IMAGE,
  routeConst.SFX,
  routeConst.THREE_D,
  routeConst.DAVINCHI_RESOLVE_TEMPLATES,
];
const categoriesRegex = channelsCategoriesRoutes.toString().split(',').join('|');

const languageRegex = process.env.VUE_APP_LANGUAGE_CODES.split(',').join('|');

export default [
  // channels categories
  {
    path: `/:lang(${languageRegex})?/:mediaTypes(${categoriesRegex})/categories`,
    name: 'channel-categories',
    component: () => import('@motionelements/landing-pages/src/modules/channel/categories/IndexPage.vue'),
  },
];
