export default {
  setPage(state, page) {
    state.pagination.page = page;
  },
  setPerPage(state, count) {
    state.pagination.perPage = count;
  },
  setTotalCount(state, totalCount) {
    state.pagination.totalCount = totalCount;
  },
};
