<template>
  <div>
    <div class="ui-dark">
      <header
          role="banner"
          class="header position-relative text-body-dark"
          itemscope
          itemtype="http://www.schema.org/WPHeader"
      >
        <TheNavDesktop class="d-none d-lg-block" />
        <TheNavMobile class="d-block d-lg-none" />
      </header>
    </div>
    <AuthModalsWrapper />
  </div>
</template>

<script>
import TheNavDesktop from './nav/TheNavBarDesktop.vue';
import TheNavMobile from './nav/TheNavBarMobile.vue';

export default {
  name: 'the-nav-bar',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TheNavDesktop,
    TheNavMobile,
    AuthModalsWrapper: () => import('./auth/AuthModalsWrapper.vue'),
  },
  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch('cart/getSummary');
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1021;
}
</style>
