import api from '@motionelements/core/src/api/base-api-v2';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';
// import axios from 'axios';

const baseUrl = '/v2/orders';

export const getOrderPreview = async (params) => {
  const url = '/v2/orders/preview';
  return api.get(url, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const createOrder = async (params) => {
  const url = '/v2/orders';
  return api.request('post', url, {
    data: params,
    params: api.getLocaleParams(),
  });
};

export const createCheckoutSession = async (params) => {
  const url = '/v2/orders/checkout-session';
  return api.request('post', url, {
    data: params,
    params: api.getLocaleParams(),
  });
};

/*
export const createStripeSetupIntent = async (params) => {
  const url = '/v2/orders/setup-intent';
  return api.request('post', url, {
    data: {
      paymentGateway: 'stripe',
      ...params,
    },
    params: api.getLocaleParams(),
  });
};
 */

export const createPaymentIntent = async (params) => {
  const url = '/v2/orders/payment-intent';
  return api.request('post', url, {
    data: {
      paymentGateway: 'stripe',
      ...params,
    },
    params: api.getLocaleParams(),
  });
};

export const completeOrder = async (id, params) => {
  const url = `/v2/orders/${id}/complete`;
  return api.request('patch', url, {
    data: params,
    params: api.getLocaleParams(),
  });
};

export const trackOrder = async (id) => {
  const url = `/v2/orders/${id}/track`;
  return api.request('patch', url, {
    params: api.getLocaleParams(),
  });
};

/*
async function withPolling(callback, interval) {
  const data = await callback();

  // If no polling interval is given we
  // return a regular object with the data.
  if (!interval) return { data };

  // Otherwise, we create a new `Vue.observable()`
  // instance and refetch the data according to
  // the specified polling interval.
  const observableData = Vue.observable({ data });
  const poll = () => setTimeout(async () => {
    observableData.data = { ...(await callback()) };
    poll();
  }, interval);

  const timerID = poll();

  console.log('timer', timerID);

  return observableData;
}

export const pollOrderCompleted = async (id, params) => {
  const url = `/v2/orders/${id}/completed`;
  const interval = 2000;
  const response = withPolling(() => api.request('patch', url, {
    data: params,
    params: api.getLocaleParams(),
  }), interval);
  console.log(response);
  return response;
};
 */

export const checkoutOrderWithStripePaymentMethod = async (id, params) => {
  const url = `/v2/orders/${id}/checkout`;
  return api.request('patch', url, {
    data: params,
    params: api.getLocaleParams(),
  });
};

export const createOrderAndCheckout = async (params) => {
  const url = '/v2/orders/checkout';
  return api.request('post', url, {
    data: params,
    params: api.getLocaleParams(),
  });
};

export const getOrder = async (id, params) => api.get(`/v2/orders/${id}`, {
  params: {
    ...api.getLocaleParams(),
    ...params,
  },
});

export const updateOrder = async (id, data) => {
  console.log(data);
  const url = `/v2/orders/${id}`;
  const request = await api.request('patch', url, {
    data: data,
    params: api.getLocaleParams(),
  });
  return request;
};

// export const listOrders = async (params) => {
//   const url = '/v2/orders';
//   return api.request('get', url, {
//     params: {
//       ...api.getLocaleParams(),
//       ...params,
//     },
//   });
// };

export const listOrders = async (params, axiosConfig) => {
  params = params || {};

  // default sort
  if (!params.sort) {
    params.sort = '-purchased_at';
  }

  const config = {
    params: {
      ...api.getLocaleParams(),
      ...snakeCaseKeys(params),
    },
    ...(axiosConfig || {}),
  };
  // return api.requestMock('get', baseUrl, config);
  return api.request('get', baseUrl, config);
};
/*
export const listOrdersMock = async (params, axiosConfig) => {
  params = params || {};
  // default sort
  if (!params.sort) {
    params.sort = '-purchased_at';
  }

  const mockApi = axios.create({
    baseURL: process.env.VUE_APP_API_MOCK_URL,
    headers: {
      'api-version': '2020-02-12',
      'x-api-key': process.env.VUE_APP_MOCK_API_KEY,
    },
    withCredentials: true,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
    ...(axiosConfig || {}),
  });

  return mockApi.get('/v2/orders');
};
 */

// eslint-disable-next-line
export const downloadOrderReceiptPdf = (orderId) => {
  return api.get(`${baseUrl}/${orderId}/receipt`, {
    params: {
      ...api.getLocaleParams(),
    },
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf', // use Accept header to download pdf
    },
  });
};
