<template>
  <div class="horizontal-scrollable" :class="{ 'transparent-bg': isTransparent }">
    <RecentlyVisitedList :showItems="showItems" />
  </div>
</template>

<script>
import RecentlyVisitedList from '@motionelements/core/src/components/nav/RecentlyVisitedList.vue';

export default {
  name: 'NavRecentlyVisitedList',
  components: {
    RecentlyVisitedList,
  },
  props: {
    isTransparent: {
      type: Boolean,
      required: true,
    },
    showItems: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
.transparent-bg {
  &::v-deep {
    .nav-link {
      color: white;
    }
  }
}
.horizontal-scrollable {
  position: relative;
  overflow: hidden;
}
.horizontal-scrollable:not(.transparent-bg) {
  &:after, &:before {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1rem;
    .header-transparent & {
      display: none;
    }
  }
  &:after {
    right: 0;
    background: linear-gradient(to right, rgba($gray-50, 0), rgba($gray-50, 1));
  }
  &:before {
    left: 0;
    background: linear-gradient(to right, rgba($gray-50, 1), rgba($gray-50, 0));
  }
}

.ui-dark {
  .horizontal-scrollable:not(.transparent-bg) {
    &:after {
      background: linear-gradient(to right, rgba($gray-950, 0), rgba($gray-950, 1));
    }
    &:before {
      background: linear-gradient(to right, rgba($gray-950, 1), rgba($gray-950, 0));
    }
  }
}
</style>
