import { library } from '@fortawesome/fontawesome-svg-core';

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

import {
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faFacebookF,
  faFacebookSquare,
  faSoundcloud,
  faTwitter,
  faVimeoV,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faCommentsAlt as falCommentsAlt,
  faDownload as falDownload,
  faFileAlt as falFileAlt,
  faFileInvoice as falFileInvoice,
  faFilter as falFilter,
  faHeart as falHeart,
  faHistory as falHistory,
  faLanguage as falLanguage,
  faMusic as falMusic,
  faObjectGroup as falObjectGroup,
  faPaperPlane as falPaperPlane,
  faPencil as falPencil,
  faPlug as falPlug,
  faShoppingCart as falShoppingCart,
  faSurprise as falSurprise,
  faTachometerAltFastest as falTachometerAltFastest,
  faTimes as falTimes,
  faWallet as falWallet,
} from '@fortawesome/pro-light-svg-icons';

import {
  faArrowLeft as farArrowLeft,
  faBan as farBan,
  faBullhorn as farBullhorn,
  faCalendarStar as farCalendarStar,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faClock as farClock,
  faCode as farCode,
  faCrown as farCrown,
  faDollarSign as farDollarSign,
  faDownload as farDownload,
  faExclamationTriangle as farExclamationTriangle,
  faExternalLink as farExternalLink,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFile as farFile,
  faFileInvoice as farFileInvoice,
  faFilm as farFilm,
  faFilter as farFilter,
  faGem as farGem,
  faGifts as farGifts,
  faGlobe as farGlobe,
  faHandHoldingHeart as farHandHoldingHeart,
  faHeart as farHeart,
  faLanguage as farLanguage,
  faLightbulbOn as farLightbulbOn,
  faMinus as farMinus,
  faMusic as farMusic,
  faNewspaper as farNewspaper,
  faObjectGroup as farObjectGroup,
  faPaperPlane as farPaperPlane,
  faPencil as farPencil,
  faPlus as farPlus,
  faRocket as farRocket,
  faSearch as farSearch,
  faShare as farShare,
  faShoePrints as farShoePrints,
  faShoppingCart as farShoppingCart,
  faSmilePlus as farSmilePlus,
  faTag as farTag,
  faThumbsUp as farThumbsUp,
  faTrash as farTrash,
  faVrCardboard as farVrCardboard,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAngleDoubleUp as fasAngleDoubleUp,
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faBars as fasBars,
  faBookmark as fasBookmark,
  faCamera as fasCamera,
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faChevronCircleDown as fasChevronCircleDown,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircle as fasCircle,
  faCircleNotch as fasCircleNotch,
  faCloud as fasCloud,
  faCog as fasCog,
  faCommentCheck as fasCommentCheck,
  faDesktop as fasDesktop,
  faDollarSign as fasDollarSign,
  faEnvelopeOpen as fasEnvelopeOpen,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExternalLinkAlt as fasExternalLinkAlt,
  faFileAlt as fasFileAlt,
  faFileAudio as fasFileAudio,
  faFileInvoice as fasFileInvoice,
  faFileUpload as fasFileUpload,
  faGem as fasGem,
  faHeart as fasHeart,
  faInbox as fasInbox,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faLaptop as fasLaptop,
  faLink as fasLink,
  faMinus as fasMinus,
  faMobileAlt as fasMobileAlt,
  faMoon as fasMoon,
  faPencil as fasPencil,
  faPlay as fasPlay,
  faPlug as fasPlug,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faQuestionCircle as fasQuestionCircle,
  faQuoteLeft as fasQuoteLeft,
  faSearch as fasSearch,
  faSignOutAlt as fasSignOutAlt,
  faSyncAlt as fasSyncAlt,
  faTabletAlt as fasTabletAlt,
  faTachometerAlt as fasTachometerAlt,
  faTachometerAltFastest as fasTachometerAltFastest,
  faThumbsUp as fasThumbsUp,
  faTicketAlt as fasTicketAlt,
  faTimes as fasTimes,
  faUndo as fasUndo,
  faUser as fasUser,
  faYenSign as fasYenSign,
} from '@fortawesome/pro-solid-svg-icons';

// import {
// } from '@fortawesome/pro-duotone-svg-icons';

import {
  faMeCreditsCircle as falMeCreditsCircle,
  faMeDownload as falMeDownload,
  faMeEasy as falMeEasy,
  faMeSearchAi as falMeSearchAi,
  faMeTelevision as falMeTelevision,
  faMeUserBuyer as falMeUserBuyer,
  faMeVrStereo as falMeVrStereo,
  faMeYoutube as falMeYoutube,
} from '@motionelements/assets/src/js/me-font/icons/me-font-icons-light.js';

import {
  faMeAeUnivers as farMeAeUnivers,
  faMeCreditsCircle as farMeCreditsCircle,
  faMeDownload as farMeDownload,
  faMeEasy as farMeEasy,
  faMeFiles as farMeFiles,
  faMeSaveMoney as farMeSaveMoney,
  faMeSearchAi as farMeSearchAi,
  faMeTutorial as farMeTutorial,
  faMeUnlimited as farMeUnlimited,
  faMeUserArtist as farMeUserArtist,
  faMeVrStereo as farMeVrStereo,
} from '@motionelements/assets/src/js/me-font/icons/me-font-icons-regular.js';

import {
  faMe3DModel as fasMe3DModel,
  faMeAfterEffects as fasMeAfterEffects,
  faMeAfterEffectsSquare as fasMeAfterEffectsSquare,
  faMeAnimation as fasMeAnimation,
  faMeAppleMotion as fasMeAppleMotion,
  faMeCollection as fasMeCollection,
  faMeCreditsCircle as fasMeCreditsCircle,
  faMeDavinciResolve as fasMeDavinciResolve,
  faMeDiamond as fasMeDiamond,
  faMeDownload as fasMeDownload,
  faMeEasy as fasMeEasy,
  faMeFcpEdit as fasMeFcpEdit,
  faMeImage as fasMeImage,
  faMeImages as fasMeImages,
  faMeLightning as fasMeLightning,
  faMeLottie as fasMeLottie,
  faMeMogrt as fasMeMogrt,
  faMeMotionelements as fasMeMotionelements,
  faMeMusic as fasMeMusic,
  faMePremierePro as fasMePremierePro,
  faMeSearch as fasMeSearch,
  faMeSearchAi as fasMeSearchAi,
  faMeSfx as fasMeSfx,
  faMeUserArtist as fasMeUserArtist,
  faMeUserBuyer as fasMeUserBuyer,
  faMeVideo as fasMeVideo,
  faMeVr as fasMeVr,
  faMeGraphicTemplate as fasMeGraphicTemplate,
  faMeUiTemplate as fasMeUiTemplate,
  faMePresentationTemplate as fasMePresentationTemplate,
  faMeNotionTemplate as fasMeNotionTemplate,
  faMePrompt as fasMePrompt,
} from '@motionelements/assets/src/js/me-font/icons/me-font-icons-solid.js';

// import {
// } from '@motionelements/assets/src/js/me-font/icons/me-font-icons-duotone.js';

library.add(
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faFacebookF,
  faFacebookSquare,
  faSoundcloud,
  faTwitter,
  faVimeoV,
  faYoutube,

  falChevronLeft,
  falChevronRight,
  falCommentsAlt,
  falDownload,
  falFileAlt,
  falFileInvoice,
  falFilter,
  falHeart,
  falHistory,
  falLanguage,
  falMusic,
  falObjectGroup,
  falPaperPlane,
  falPencil,
  falPlug,
  falShoppingCart,
  falSurprise,
  falTachometerAltFastest,
  falTimes,
  falWallet,

  farArrowLeft,
  farBan,
  farBullhorn,
  farCalendarStar,
  farCheck,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farClock,
  farCode,
  farCrown,
  farDollarSign,
  farDownload,
  farExclamationTriangle,
  farExternalLink,
  farEye,
  farEyeSlash,
  farFile,
  farFileInvoice,
  farFilm,
  farFilter,
  farGem,
  farGifts,
  farGlobe,
  farHandHoldingHeart,
  farHeart,
  farLanguage,
  farLightbulbOn,
  farMinus,
  farMusic,
  farNewspaper,
  farObjectGroup,
  farPaperPlane,
  farPencil,
  farPlus,
  farRocket,
  farSearch,
  farShare,
  farShoePrints,
  farShoppingCart,
  farSmilePlus,
  farTag,
  farThumbsUp,
  farTrash,
  farVrCardboard,

  fasAngleDoubleUp,
  fasAngleDown,
  fasAngleUp,
  fasBars,
  fasBookmark,
  fasCamera,
  fasCaretDown,
  fasCheck,
  fasChevronCircleDown,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCircle,
  fasCircleNotch,
  fasCloud,
  fasCog,
  fasCommentCheck,
  fasDesktop,
  fasDollarSign,
  fasEnvelopeOpen,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasExternalLinkAlt,
  fasFileAlt,
  fasFileAudio,
  fasFileInvoice,
  fasFileUpload,
  fasGem,
  fasHeart,
  fasInbox,
  fasInfo,
  fasInfoCircle,
  fasLaptop,
  fasLink,
  fasMinus,
  fasMobileAlt,
  fasMoon,
  fasPencil,
  fasPlay,
  fasPlug,
  fasPlus,
  fasPlusCircle,
  fasQuestionCircle,
  fasQuoteLeft,
  fasSearch,
  fasSignOutAlt,
  fasSyncAlt,
  fasTabletAlt,
  fasTachometerAlt,
  fasTachometerAltFastest,
  fasThumbsUp,
  fasTicketAlt,
  fasTimes,
  fasUndo,
  fasUser,
  fasYenSign,

  falMeCreditsCircle,
  falMeDownload,
  falMeEasy,
  falMeSearchAi,
  falMeTelevision,
  falMeUserBuyer,
  falMeVrStereo,
  falMeYoutube,

  farMeAeUnivers,
  farMeCreditsCircle,
  farMeDownload,
  farMeEasy,
  farMeFiles,
  farMeSaveMoney,
  farMeSearchAi,
  farMeTutorial,
  farMeUnlimited,
  farMeUserArtist,
  farMeVrStereo,

  fasMe3DModel,
  fasMeAfterEffects,
  fasMeAfterEffectsSquare,
  fasMeAnimation,
  fasMeAppleMotion,
  fasMeCollection,
  fasMeCreditsCircle,
  fasMeDavinciResolve,
  fasMeDiamond,
  fasMeDownload,
  fasMeEasy,
  fasMeFcpEdit,
  fasMeImage,
  fasMeImages,
  fasMeLightning,
  fasMeLottie,
  fasMeMogrt,
  fasMeMotionelements,
  fasMeMusic,
  fasMePremierePro,
  fasMeSearch,
  fasMeSearchAi,
  fasMeSfx,
  fasMeUserArtist,
  fasMeUserBuyer,
  fasMeVideo,
  fasMeVr,
  fasMeGraphicTemplate,
  fasMeUiTemplate,
  fasMePresentationTemplate,
  fasMeNotionTemplate,
  fasMePrompt,
);

export default function install(Vue) {
  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('font-awesome-layers', FontAwesomeLayers);
  Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
}
