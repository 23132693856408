import { getAccountProfileDetails } from '@motionelements/core/src/services/account.service.js';
import axios from 'axios';
import * as usersApi from '@/api/users.js';

// import Vue from 'vue';

const state = {
  users: {
    loading: {
      isBusy: false,
      isLoading: false,
      isError: false,
    },
    querys: {
      q: '',
      page: 1,
    },
    list: [],
    pagination: {
      totalCount: 0,
      currentPage: 1,
      perPage: 5,
    },
  },
  cancelTokens: {},
};

const getters = {
  users: state => state.users,
  usersTotalCount: state => state.users.pagination.totalCount,
  usersCurrentPage: state => state.users.pagination.currentPage,
  usersPerPage: state => state.users.pagination.perPage,
};

function refreshCancelToken(state, tokenName) {
  // cancel previous request
  if (_.has(state.cancelTokens, tokenName)) {
    const info = {
      type: 'cancelToken',
      name: `${tokenName}`,
    };
    state.cancelTokens[tokenName].cancel(info);
  }

  // create cancel token
  state.cancelTokens[tokenName] = axios.CancelToken.source();

  return state.cancelTokens[tokenName];
}

const actions = {
  listUsers({ commit }, params) {
    console.log('listUsers actions', params);
    const cancelToken = refreshCancelToken(state, 'listUsers');
    const axiosConfig = { cancelToken: cancelToken.token };
    return usersApi.listUsers(params, axiosConfig)
      .then((response) => {
        console.log('listUsers actions', params);

        commit('setUsersList', response.data.data);
        commit('setUsersPagination', response.data);
        return response;
      });
  },
  createUser({ commit, state, rootState }, params) {
    console.log('createUser actions', params);

    return usersApi.createUser(params)
      .then((response) => {
        console.log('createUser actions done', response);

        // update multi-seat info
        getAccountProfileDetails()
          .then((response) => {
            rootState.user.member = response.data.data;
          });

        // append new user into users list to first row
        const updateRow = response.data.data;
        if (state.users.list && state.users.list.length) {
          const updateUser = [...state.users.list];
          updateUser.unshift(updateRow);
          commit('setUsersList', updateUser);
        }

        return response;
      });
  },
  updateUser({ commit, state }, params) {
    console.log('updateUser actions', params);

    const userId = params.userId;
    delete params.userId;

    return usersApi.updateUser(userId, params)
      .then((response) => {
        console.log('updateUser actions done', response);

        // hot reload after update
        const updateRow = response.data.data;
        if (state.users.list && state.users.list.length) {
          const updateUser = [...state.users.list];

          const index = state.users.list.findIndex(x => x.id === updateRow.id);
          if (index !== -1) {
            updateUser[index] = updateRow;
          }

          commit('setUsersList', updateUser);
        }

        return response;
      });
  },
  deleteUser({ commit, state, rootState }, userId) {
    console.log('deleteUser actions', userId);

    return usersApi.deleteUser(userId)
      .then((response) => {
        console.log('deleteUser actions done', response);

        // update multi-seat info
        getAccountProfileDetails()
          .then((response) => {
            rootState.user.member = response.data.data;
          });

        // remove user from list
        if (state.users.list && state.users.list.length) {
          const deleteId = userId;
          let usersList = [...state.users.list];
          const index = usersList.findIndex(x => x.id === deleteId);
          if (index !== -1) {
            delete usersList[index];
            usersList = usersList.filter(x => x !== undefined);
            commit('setUsersList', usersList);
          }
        }

        return response;
      });
  },
  // eslint-disable-next-line no-unused-vars
  allocateCredits({ commit, state, rootState }, { userId, credits }) {
    if (!userId) return false;
    if (!credits) return false;

    const params = {
      credits: credits,
    };

    return usersApi.allocateCredits(userId, params)
      .then((response) => {
        console.log('allocateCredits actions done', response);

        // hot reload after update
        const updateRow = response.data.data;
        if (state.users.list && state.users.list.length) {
          const updateUser = [...state.users.list];

          const index = state.users.list.findIndex(x => x.id === updateRow.id);
          if (index !== -1) {
            updateUser[index] = updateRow;
          }

          commit('setUsersList', updateUser);
        }

        // update credits info
        this.commit('user/setLoading', true);
        getAccountProfileDetails()
          .then((response) => {
            // rootState.user.member = response.data.data;
            this.dispatch('user/setMember', response.data.data);
          })
          .finally(() => {
            this.commit('user/setLoading', false);
          });

        return response;
      });
  },
  // eslint-disable-next-line no-unused-vars
  resendActivationEmail({ state }, { userId }) {
    if (!userId) return false;

    return usersApi.sendActivationEmail(userId)
      .then((response) => {
        console.log('resendActivationEmail actions done', response);
        return response;
      });
  },

};

const mutations = {
  setUsersList(state, list) {
    state.users.list = list;
  },
  setUsersPagination(state, data) {
    state.users.pagination.currentPage = data.page;
    state.users.pagination.perPage = data.perPage;
    state.users.pagination.totalCount = data.totalCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
