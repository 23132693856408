/**
 * FORMS ROUTES
 */

export default [
  {
    path: '/:lang?/forms/contact-reseller',
    name: 'contact-reseller',
    component: () => import(/* webpackChunkName: "contact-reseller" */ '@/views/forms/ContactReseller.vue'),
  },
];
