import routeConst from './const.js';

/**
 * AFFILIATE ROUTES
 */

export default [
  {
    path: '/:lang?/affiliate',
    component: () => import('@/views/account/Account.vue'),
    children: [
      {
        path: '',
        redirect: routeConst.DASHBOARD,
      },
      {
        path: 'report',
        redirect: 'report/referral',
      },
      {
        path: 'report/referral',
        name: 'affiliate-report-referral',
        component: () => import(/* webpackChunkName: "affiliate" */ '@/views/affiliate/Affiliate.vue'),
      },
      {
        path: 'connect-wise',
        name: 'affiliate-connect-wise',
        component: () => import('@motionelements/core/src/components/payouts/wise/ConnectWise.vue'),
      },
    ],
  },
];
