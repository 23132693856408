<template>
  <b-dropdown ref="dropdown" :variant="variant" right no-caret menu-class="nav-dropdown" @show="emitOnShow">
    <template #button-content>
      <font-awesome-icon :icon="faEarthAsia" class="mr-1" />
      {{ languageLabel }}
    </template>
    <MenuLanguagesList @onChange="hideDropdown" />
    <slot></slot>
  </b-dropdown>
</template>

<script>
import { faEarthAsia } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'menu-language-nav-dropdown',
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  components: {
    MenuLanguagesList: () => import('./MenuLanguagesList.vue'),
  },
  data() {
    return {
      faEarthAsia,
    };
  },
  watch: {
    '$route'() {
      this.hideDropdown();
    },
  },
  computed: {
    languageLabel() {
      return _.get(this.$store.getters['app/getLanguageByCode'](this.$i18n.locale), 'name');
    },
  },
  methods: {
    emitOnShow() {
      this.$emit('onShow');
    },
    hideDropdown() {
      this.$refs.dropdown.hide(true);
    },
  },
};
</script>
