<template>
  <div v-show="isOpen" class="flash-message alert alert-dismissable rounded-0 mb-0" :class="type" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="$root.$on('emit-flash-toggle', false)">×</button>
    <template v-if="msg === 'credentials'">
      {{ $t('login.error_credentials') }}
      <a :href="$link('/account/forgot-password').url()">{{ $t('login.forgot_password') }}</a>
    </template>
    <template v-else-if="msg === 'recaptcha'">
      {{ $t('login.error_recaptcha') }}
    </template>
    <template v-else-if="msg === 'csrf'">
      {{ $t('login.error_csrf') }}
    </template>
    <template v-else-if="msg === 'artist_signup'">
      {{ $t('login.goal_artist_signup') }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'flash-message',
  data() {
    return {
      isOpen: false,
      msg: 'credentials',
      type: 'alert-danger',
    };
  },
  mounted() {
    this.$root.$on('emit-flash-toggle', (event) => {
      this.isOpen = event;
    });
    this.$root.$on('emit-flash-message', (event) => {
      this.msg = event;
      this.type = 'alert-danger';
    });
    this.$root.$on('emit-flash-info', (event) => {
      this.msg = event;
      this.type = 'alert-info';
    });
  },
};
</script>

<style lang="scss" scoped>
  .alert-danger {
    color: #581135;
    background-color:#f27ab7;
    border-color:#eb2388;
  }
  .alert {
    position: relative;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
  }
</style>
