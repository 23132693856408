import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // polyfill for lozad

import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';
import VueScrollTo from 'vue-scrollto';
import VueLazyload from 'vue-lazyload';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VueHtmlToPaper from 'vue-html-to-paper';
// import VueConfirmDialog from 'vue-confirm-dialog';
import Rollbar from 'vue-rollbar';
import VueConfirmDialog from 'vue-confirm-dialog';

import * as tools from '@motionelements/helper/tools.js';
// import api from '@/api/index.js';
import player from '@motionelements/helper/player/index.js';

import cookies from 'js-cookie';

import uuid from 'uuid/v4'; // upload file id generate
import _ from 'lodash';
import meHelper from '@motionelements/helper';

import * as teleport from '@motionelements/teleport';

import {
  ja,
  ko,
} from 'date-fns/locale';

import { format } from 'date-fns';

// eslint-disable-next-line
import {
  NavPlugin,
  VBScrollspyPlugin,
  ModalPlugin,
  TablePlugin,
  ListGroupPlugin,
  CollapsePlugin,
  DropdownPlugin,
  SidebarPlugin,
} from 'bootstrap-vue';
import VTooltip from 'v-tooltip';

import link from '@motionelements/core/src/helpers/link.js';
import date from '@motionelements/core/src/helpers/date.js';
// init services store
import { setStore as setAccountServiceStore } from '@motionelements/core/src/services/account.service.js';
import { setStore as setAppServiceStore } from '@motionelements/core/src/services/app.service.js';
import { EventBus } from '@motionelements/core/src/services/event-bus.service.js';
import { setStore as setNotificationServiceStore } from '@motionelements/core/src/services/notification.service.js';
import { setStore as setLanguageStore } from '@motionelements/core/src/services/language.service.js';
import { setStore as setApiStore } from '@motionelements/core/src/api/base-api-v2';

import iconBase from '@motionelements/assets/src/js/me-font/icons-base.js';


// import enPackage from '@motionelements/landing-pages/src/lang/json/en.json';
import App from './App.vue';
import router from './router';
import store from './store';

import appConst from './tools/appConst.js';

// vue layout define
import defaultLayout from './layouts/default.vue';
import minimalLayout from './layouts/minimal.vue';
import iconFont from './assets/js/me-font/icons-pages.js';

import vueHtmlToPaperOptions from './helpers/vue-html-to-paper-options.js';

/*
lazyload language
import en from './lang/json/en.json';
const languages = _.merge(en, enPackage);
 */

import i18n from './lang/i18n.js';


Vue.component('default-layout', defaultLayout);
// Vue.component('embed-layout', embed);
Vue.component('minimal-layout', minimalLayout);

// eslint-disable-next-line no-unused-expressions
import(/* webpackPrefetch: true */'bootstrap');

const isProduction = process.env.VUE_APP_ENV === 'production';

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  hostSafeList: ['motionelements.com', 'cloudfront.net'],
  captureUncaught: !isProduction,
  captureUnhandledRejections: !isProduction,
  verbose: !isProduction,
  // enabled: (['production', 'stage'].indexOf(process.env.NODE_ENV) >= 0) ? false : false,
  enabled: ['production', 'staging'].includes(process.env.VUE_APP_ENV),
  environment: process.env.VUE_APP_ENV,
  // payload: {
  //   client: {
  //     javascript: {
  //       code_version: '1.0',
  //     },
  //   },
  // },
});


Vue.use(VueMeta, {
  keyName: 'head', // compatible with Nuxt
  tagIDKeyName: 'hid', // compatible with Nuxt
});
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(TablePlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(VTooltip, {
  defaultTrigger: 'click hover',
});

Vue.use(VBScrollspyPlugin);
Vue.use(ListGroupPlugin);
Vue.use(CollapsePlugin);
Vue.use(SidebarPlugin);
Vue.use(DropdownPlugin);
Vue.use(VueScrollTo, { duration: 800 });
Vue.use(iconBase);
Vue.use(iconFont);
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);
Vue.use(VueConfirmDialog);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 2,
});

Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

// Vue.use(VueConfirmDialog);
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

// function setI18nLanguage(lang, messages) {
//   console.log(lang, messages);
//   i18n.locale = lang;
//   i18n.setLocaleMessage(lang, messages.default);
//   loadedLanguages.push(lang);
//   document.querySelector('html').setAttribute('lang', lang);
//   return lang;
// }

// async function loadLanguageAsync(lang) {
//   // If the same language
//   if (lang !== 'en') {
//     if (i18n.locale === lang) {
//       return Promise.resolve(setI18nLanguage(lang, languages));
//     }
//
//     // If the language was already loaded
//     if (loadedLanguages.includes(lang)) {
//       return Promise.resolve(setI18nLanguage(lang, languages));
//     }
//   }
//
//   // If the language hasn't been loaded yet
//   switch (lang) {
//     case 'es':
//       return import(
//         './lang/es.js').then(messages => setI18nLanguage(lang, messages));
//     case 'fr':
//       return import('./lang/fr.js').then(messages => setI18nLanguage(lang, messages));
//     case 'hu':
//       return import('./lang/hu.js').then(messages => setI18nLanguage(lang, messages));
//     case 'it':
//       return import('./lang/it.js').then(messages => setI18nLanguage(lang, messages));
//     case 'ja':
//       return import(
//         /* webpackChunkName: "i18n-ja" */
//         './lang/ja.js').then(messages => setI18nLanguage(lang, messages));
//     case 'ko':
//       return import('./lang/ko.js').then(messages => setI18nLanguage(lang, messages));
//     case 'pt':
//       return import('./lang/pt.js').then(messages => setI18nLanguage(lang, messages));
//     case 'ru':
//       return import('./lang/ru.js').then(messages => setI18nLanguage(lang, messages));
//     case 'th':
//       return import('./lang/th.js').then(messages => setI18nLanguage(lang, messages));
//     case 'tr':
//       return import('./lang/tr.js').then(messages => setI18nLanguage(lang, messages));
//     case 'zh_hant':
//       return import('./lang/zh_hant.js').then(messages => setI18nLanguage(lang, messages));
//     case 'de':
//       return import('./lang/de.js').then(messages => setI18nLanguage(lang, messages));
//     case 'en':
//     default:
//       return import('./lang/en.js').then(messages => setI18nLanguage(lang, messages));
//   }
// }

Vue.use(VueGtm, {
  // container id
  id: process.env.VUE_APP_GTM_ID,
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: process.env.VUE_APP_GTM_AUTH,
  //   gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
  //   gtm_cookies_win: 'x',
  // },
  vueRouter: router,
  // enable track or not (optional)
  enabled: !['testing'].includes(process.env.VUE_APP_ENV),
  // enabled: window.location.hostname === 'www.motionelements.com' ? true : false,
  // display console logs debugs or not (optional)
  debug: !isProduction,
  // debug: (['production', 'development'].indexOf(process.env.NODE_ENV) >= 0) ? true : true,
});

setAccountServiceStore(store);
setAppServiceStore(store);
setNotificationServiceStore(store);
setApiStore(store);
setLanguageStore(store);


Vue.prototype._ = _;
// eslint-disable-next-line no-underscore-dangle
Vue.prototype.$_ = _; // use $_ for Nuxt compatiblity
Vue.prototype.$scrollToTop = () => window.scrollTo({ top: 0 });
Vue.prototype.$scrollTo = (selector) => { document.querySelector(selector).scrollIntoView({ behavior: 'smooth' }); };
Vue.prototype.uuid = uuid;
Vue.prototype.$cookies = cookies;
Vue.prototype.$audioPlayer = player.audioPlayer;
Vue.prototype.$videoPlayer = player.videoPlayer;
Vue.prototype.$player = player;
Vue.prototype.$nl2p = tools.nl2p;
Vue.prototype.$term = meHelper.term;
// Vue.prototype.$term = term;
Vue.prototype.$siteUrl = meHelper.siteUrl;
Vue.prototype.$site = meHelper.site;
Vue.prototype.$link = link;
Vue.prototype.$wbr = meHelper.wbr;
Vue.prototype.$date = date;
Vue.prototype.$teleport = teleport;
// Vue.prototype.$api = api;
Vue.prototype.$tools = tools;
Vue.prototype.$appConst = appConst;
// date-fns
Vue.prototype.$dateFns = {};
Vue.prototype.$dateFns.locale = {};
Vue.prototype.$dateFns.locale.ja = ja;
Vue.prototype.$dateFns.locale.ko = ko;
Vue.prototype.$dateFns.format = format;

Vue.prototype.$bus = EventBus;

Vue.prototype.$VueBus = new Vue();

Vue.config.errorHandler = function (err, vm) {
  if (!isProduction) {
    vm.$rollbar.error(err);
    throw err;
  }
};

Vue.config.productionTip = true;
Vue.config.devtools = !isProduction;
Vue.config.performance = !isProduction;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
